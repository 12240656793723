/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Button,
  Dialog,
  Select,
  MenuItem,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Autocomplete,
  DialogActions,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

function SdDialog({
  data, isOpen, setIsOpen,
}) {
  const generateField = (field) => {
    switch (field.type) {
      case 'hidden':
        return (
          <input
            id={field.id}
            name={field.id}
            label={field.label}
            type="hidden"
            value={field.defaultValue || ''}
          />
        );
      case 'check':
        return (
          <FormControlLabel
            control={(
              <Checkbox
                id={field.id}
                name={field.id}
                disabled={field.disabled}
                defaultChecked={field.defaultValue || false}
              />
)}
            label={field.label}
          />

        );
      case 'file':
        return (
          <TextField
            type="file"
            id={field.id}
            name={field.id}
            label={field.label}
          />
        );
      case 'text':
        return (
          <TextField
            required={field.required}
            margin="dense"
            id={field.id}
            name={field.id}
            label={field.label}
            type="text"
            fullWidth
            variant="standard"
            defaultValue={field.defaultValue || ''}
          />
        );
      case 'date':
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              defaultValue={dayjs(field.defaultValue) || null}
              required={field.required}
              margin="dense"
              id={field.id}
              name={field.id}
              label={field.label}
              type="text"
              fullWidth
              variant="standard"
            />
          </LocalizationProvider>
        );
      case 'email':
        return (
          <TextField
            required={field.required}
            margin="dense"
            id={field.id}
            name={field.id}
            label={field.label}
            type="email"
            fullWidth
            variant="standard"
            defaultValue={field.defaultValue || ''}
          />
        );
      case 'password':
        return (
          <TextField
            required={field.required}
            margin="dense"
            id={field.id}
            name={field.id}
            label={field.label}
            type="password"
            fullWidth
            variant="standard"
            defaultValue={field.defaultValue || ''}
          />
        );
      case 'number':
        return (
          <TextField
            required={field.required}
            margin="dense"
            id={field.id}
            name={field.id}
            label={field.label}
            type="number"
            fullWidth
            variant="standard"
            defaultValue={field.defaultValue || ''}
          />
        );
      case 'select':
        return (
          <Select
            required={field.required}
            labelId="demo-simple-select-label"
            id={field.id}
            name={field.id}
            label={field.label}
            onChange={(e) => {
              console.log(e.target);
            }}
          >
            {field?.values.map((value) => (
              <MenuItem value={value.value}>{value.name}</MenuItem>
            ))}
          </Select>
        );
      case 'autocomplete':
        return (
          <Autocomplete
            required={field.required}
            disablePortal
            options={field.values}
            sx={{ width: '100%' }}
            renderInput={(params) => (
              <TextField
                {...params}
                id={field.id}
                name={field.id}
                label={field.label}
              />
            )}
            defaultValue={field.defaultValue || ''}
          />
        );
      default:
        return (<>field is missing</>);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        component: 'form', // form ||  ''
        onSubmit: data?.buttons?.ok?.action,
      }}
    >
      <DialogTitle>
        {data?.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div dangerouslySetInnerHTML={{ __html: data?.description }} />
        </DialogContentText>

        {data?.type === 'form'
            && data.fields.map((field) => generateField(field))}

      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsOpen(false)}>Cancel</Button>
        {data?.type === 'form' ? (
          <Button type="submit">{data?.buttons?.ok?.title}</Button>
        )
          : (
            <Button type="button" onClick={data?.buttons?.ok?.action}>{data?.buttons?.ok?.title}</Button>
          )}
      </DialogActions>
    </Dialog>
  );
}

export default SdDialog;
