/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router, Routes, Route, Navigate,
} from 'react-router-dom';
import './App.scss';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import english from './i18n/en';
import Login from './pages/Login/Login';
import PersonalAssistant from './pages/PersonalAssistant/PersonalAssistant';
import PaymentPage from './pages/PaymentPage/PaymentPage';
import Demo from './pages/Demo/Demo';
import DemoChat from './pages/DemoChat/DemoChat';
import Dashboard from './pages/Dashboard/Dashboard';
import DashboardSales from './pages/DashboardSales/DashboardSales';
import DashboardAi from './pages/DashboardAi/DashboardAi';
import Users from './pages/Users/Users';
import Groups from './pages/Groups/Groups';
import Permissions from './pages/Permissions/Permissions';
import Assistants from './pages/Assistants/Assistants';
import Company from './pages/Company/Company';
import CompanySettings from './pages/CompanySettings/CompanySettings';
import Cases from './pages/Cases/Cases';
import Imports from './pages/Imports/Imports';
import AiStatistics from './pages/AiStatistics/AiStatistics';
import AiConfiguration from './pages/AiConfiguration/AiConfiguration';
import Billing from './pages/Billing/Billing';
import Invoices from './pages/Invoices/Invoices';
import Links from './pages/Links/Links';
import CallsHistory from './pages/CallsHistory/CallsHistory';
import Payments from './pages/Payments/Payments';
import Hooks from './pages/Hooks/Hooks';
import AppFrame from './AppFrame';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation:
          english,
      },
    },
    lng: 'en',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  });

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/account" element={<AppFrame />}>
          <Route element={<Dashboard />} path="dashboard" exact />
          <Route element={<DashboardSales />} path="dashboard/sales" exact />
          <Route element={<DashboardAi />} path="dashboard/ai" exact />

          <Route element={<Users />} path="user-management/users" exact />
          <Route element={<Groups />} path="user-management/groups" exact />
          <Route element={<Permissions />} path="user-management/permissions" exact />

          <Route element={<Assistants />} path="assistants" exact />

          <Route element={<Company />} path="companies" exact />
          <Route element={<CompanySettings />} path="companies/settings" exact />

          <Route element={<Cases />} path="cases" exact />
          <Route element={<Imports />} path="imports" exact />
          <Route element={<Links />} path="cases/links" exact />
          <Route element={<CallsHistory />} path="cases/calls" exact />
          <Route element={<Payments />} path="cases/payments" exact />

          <Route element={<AiStatistics />} path="ai/statistics" exact />
          <Route element={<AiConfiguration />} path="ai/ocnfiguration" exact />

          <Route element={<Billing />} path="billing" exact />
          <Route element={<Invoices />} path="billing/invoices" exact />

          <Route element={<Hooks />} path="configuration/hooks" exact />
        </Route>

        <Route element={<PersonalAssistant />} path="assistant/:linkId" />
        <Route element={<PaymentPage />} path="bank/info/:linkId" />

        <Route element={<Demo />} path="demo/live" />
        <Route element={<DemoChat />} path="demo/chat" />

        <Route element={<Login />} path="/" />

        <Route path="*" element={<Navigate to="/account/dashboard" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
