const checkPermission = (permissions, object, action) => {
  let hasPermission = false;
  if (Object.keys(permissions).length > 0) {
    permissions.permissions.forEach((permission) => {
      if (permission.object === object) {
        hasPermission = permission.permissions[action];
      }
    });
  }
  return hasPermission;
};

export default checkPermission;
