/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useEffect, useContext, useState, useCallback,
} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import { Button } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment';
import { SecurityContext, NotificationContext } from '../../Context';
import SdDialog from '../../components/Dialog/Dialog';
import checkPermission from '../../helpers/helpers';
import iconCsv from '../../assets/img/icon_csv.svg';
import iconXlsx from '../../assets/img/icon_xlsx.svg';

import './Imports.scss';
import Api from '../../api/api';

const columns = [
  { field: '_id', headerName: 'Id', width: 70 },
  { field: 'fileName', headerName: 'First name', width: 130 },
  {
    field: 'company', headerName: 'Company', width: 130, valueGetter: (company) => company?.value?.name,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 130,
    valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY hh:mm A'),
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 130,
    valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY hh:mm A'),
  },
];

function Cases() {
  const { permissions, setPermissions } = useContext(SecurityContext);

  const [dialogData, setDialogData] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [imports, setImports] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filtereddRows, setFilteredRows] = useState([]);
  const { notification, setNotification } = useContext(NotificationContext);

  const getImports = () => {
    Api.getImports().then((res) => {
      setImports(res.data.body || {});
    });
  };

  useEffect(() => {
    getImports();
  }, []);

  const handleTemplateDownload = useCallback(
    (ext) => {
      switch (ext) {
        case 'csv':
          window.open(`${window.location.origin}/assets/import_template_1.8.1.csv`, '_blank', 'noreferrer');
          break;
        case 'xlsx':
          window.open(`${window.location.origin}/assets/import_template_1.8.1.xlsx`, '_blank', 'noreferrer');
          break;
        default:
          break;
      }
    },
    [],
  );

  const uploadFile = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries((formData).entries());
    Api.uploadCases({
      file: data.file,
      userId: permissions.userId,
      companyId: permissions.companyId,
    }).then((res) => {
      setNotification({
        ...notification,
        open: true,
        message: 'Successfully imported all the cases!',
      });
      getImports();
    })
      .catch((err) => {
        setNotification({
          ...notification,
          open: true,
          message: 'There was an error uploading the file',
        });
      });
    setIsDialogOpen(false);
    getImports();
  };

  const handleUpload = () => {
    setDialogData({
      type: 'form',
      title: 'Upload cases',
      description: '',
      fields: [
        {
          type: 'file',
          id: 'file',
          defaultValue: null,
        },
      ],
      buttons: {
        ok: {
          title: 'Create',
          action: uploadFile,
        },
      },
    });
    setIsDialogOpen(true);
  };

  return (
    <>
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item"><a href="/#">Cases</a></li>
            <li className="breadcrumb-item active" aria-current="page">Imports Management</li>
          </ol>
          <h4 className="main-title mb-0">Imports Management</h4>
        </div>
        <div className="d-flex gap-2 mt-3 mt-md-0">
          <Button
            variant=""
            role="link"
            onClick={() => handleTemplateDownload('csv')}
          >
            <Tooltip title="Download CSV template">
              <img src={iconCsv} alt="" />
            </Tooltip>
          </Button>
          <Button
            variant=""
            role="link"
            onClick={() => handleTemplateDownload('xlsx')}
          >
            <Tooltip title="Download Excel - XLSX template">
              <img src={iconXlsx} alt="" />
            </Tooltip>
          </Button>
          <Button variant="" onClick={handleUpload} disabled={!checkPermission(permissions, 'case', 'write')}>
            <Tooltip title="Upload cases">
              <UploadIcon />
            </Tooltip>
          </Button>
        </div>
      </div>

      <div className="col-12">
        <div className="card card-one logged">
          <DataGrid
            getRowId={(row) => row._id}
            rows={imports}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            onRowSelectionModelChange={(rows, details) => {
              setSelectedRows(rows);
            }}
          />
        </div>
      </div>

      <SdDialog
        data={dialogData}
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
      />
    </>
  );
}

export default Cases;
