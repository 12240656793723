/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useContext, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import CsvDownloader from 'react-csv-downloader';
import moment from 'moment';
import { NotificationContext, SecurityContext } from '../../Context';
import './CallsHistory.scss';
import Api from '../../api/api';
import SdDialog from '../../components/Dialog/Dialog';

const columns = [
  { field: '_id', headerName: 'Id', width: 70 },
  {
    field: 'callId', headerName: 'Call Id', width: 250,
  },
  {
    field: 'assistantId', headerName: 'Assistant Id', width: 250, valueGetter: ({ row }) => row.case.assistantId,
  },
  {
    field: 'case.id', headerName: 'Case Id', width: 250, valueGetter: ({ row }) => row.case._id,
  },
  {
    field: 'name', headerName: 'Receiver name', width: 130, valueGetter: ({ row }) => `${row.case.firstName} ${row.case.lastName}`,
  },
  {
    field: 'phone', headerName: 'Receiver phone', width: 200, valueGetter: ({ row }) => row.case.phone,
  },
  {
    field: 'email', headerName: 'Receiver email', width: 275, valueGetter: ({ row }) => row.case.email,
  },
  {
    field: 'status', headerName: 'Call Status', width: 130,
  },
  {
    field: 'endedReason', headerName: 'Ended Reason', width: 130,
  },
  {
    field: 'summary', headerName: 'Call Summary', width: 500,
  },
  {
    field: 'transcript',
    headerName: 'Download Transcript',
    width: 275,
    renderCell: ({ row }) => {
      const csvColumns = [{
        id: 'emitter',
        displayName: 'Emitter',
      }, {
        id: 'message',
        displayName: 'Message',
      }, {
        id: 'time',
        displayName: 'Time sent',
      }];

      const datas = row?.transcript && row?.transcript.map((line) => {
        if (line.role !== 'system') {
          return {
            emitter: line.role === 'user' ? `${row.case.firstName} ${row.case.lastName}` : process.env.REACT_APP_AGENT_NAME,
            message: line.message,
            time: moment(line.time).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          };
        }
        return false;
      });

      return (row.transcript && (
      <CsvDownloader
        filename={row.callId}
        extension=".csv"
        separator=";"
        wrapColumnChar="'"
        columns={csvColumns}
        datas={datas}
        text="DOWNLOAD"
      />
      ));
    },
  },
  {
    field: 'recording',
    headerName: 'Call Rcording',
    width: 275,
    renderCell: ({ row }) => row.recording && (
      <audio controls>
        <source src={row.recording} type="audio/mpeg" />
      </audio>
    ),
  },
  {
    field: 'createdAt',
    headerName: 'Call Initiated',
    width: 200,
    valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY hh:mm A'),
  },
  {
    field: 'updatedAt',
    headerName: 'Call Ended',
    width: 200,
    valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY hh:mm A'),
  },
];

function CallsHistory() {
  const { permissions, setPermissions } = useContext(SecurityContext);
  const [calls, setCalls] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filtereddRows, setFilteredRows] = useState([]);
  const [groups, setGroups] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [dialogData, setDialogData] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { notification, setNotification } = useContext(NotificationContext);

  const getLinks = () => {
    Api.getCalls().then((res) => {
      setCalls(res.data.body);
    });

    Api.getCompanies().then((res) => {
      setCompanies(res.data.body);
    });
  };

  useEffect(() => {
    getLinks();
  }, []);

  return (
    <>
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item"><a href="/#">Cases</a></li>
            <li className="breadcrumb-item active" aria-current="page">Calls History</li>
          </ol>
          <h4 className="main-title mb-0">Calls History</h4>
        </div>
        <div className="d-flex gap-2 mt-3 mt-md-0">
          {/* <Button variant="" onClick={() => handleDialogOpen('add')} disabled={!checkPermission(permissions, 'case', 'write')}>
            <Tooltip title="Add new case">
              <AddIcon />
            </Tooltip>
          </Button> */}
          {/* <Button variant="" disabled={selectedRows?.length !== 1 || !checkPermission(permissions, 'case', 'edit')} onClick={() => handleDialogOpen('edit')}>
            <Tooltip title="Edit case">
              <EditIcon />
            </Tooltip>
          </Button> */}
          {/* <Button variant="" disabled={selectedRows?.length === 0 || !checkPermission(permissions, 'case', 'delete')} onClick={() => handleDialogOpen('delete')}>
            <Tooltip title="Delete case(s)">
              <DeleteIcon />
            </Tooltip>
          </Button> */}
        </div>
      </div>

      <div className="col-12">
        <div className="card card-one logged">
          <DataGrid
            getRowId={(row) => row._id}
            rows={calls}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            onRowSelectionModelChange={(rows, details) => {
              setSelectedRows(rows);
            }}
          />
        </div>
      </div>

      <SdDialog
        data={dialogData}
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
      />

    </>
  );
}

export default CallsHistory;
