/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useContext, useState } from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import { NotificationContext, SecurityContext } from '../../Context';
import checkPermission from '../../helpers/helpers';
import './Payments.scss';
import Api from '../../api/api';
import SdDialog from '../../components/Dialog/Dialog';

const columns = [
  { field: '_id', headerName: 'Id', width: 70 },
  {
    field: 'firstName', headerName: 'First Name', width: 130, valueGetter: ({ row }) => `${row.case.firstName}`,
  },
  {
    field: 'lastName', headerName: 'Last Name', width: 130, valueGetter: ({ row }) => `${row.case.lastName}`,
  },
  {
    field: 'phone', headerName: 'Phone', width: 130, valueGetter: ({ row }) => `${row.case.phone}`,
  },
  {
    field: 'email', headerName: 'Email', width: 130, valueGetter: ({ row }) => `${row.case.email}`,
  },
  {
    field: 'amount', headerName: 'Amount paid', width: 130, valueGetter: ({ row }) => `${row.amount} ${row.currency}`,
  },
  {
    field: 'isConfirmed', headerName: 'Is Confirmed', width: 130, valueGetter: ({ row }) => `${row.isConfirmed ? 'Yes' : 'No'}`,
  },
  {
    field: 'createdAt',
    headerName: 'Payment date',
    width: 130,
    valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY hh:mm A'),
  },
  {
    field: 'updatedAt',
    headerName: 'Last update',
    width: 130,
    valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY hh:mm A'),
  },
];

function Payments() {
  const { permissions, setPermissions } = useContext(SecurityContext);
  const [payments, setPayments] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [dialogData, setDialogData] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { notification, setNotification } = useContext(NotificationContext);

  const getLinks = () => {
    Api.getPayments().then((res) => {
      console.log(' xxxx ', res.data.payments);
      setPayments(res.data?.payments || []);
    });
  };

  useEffect(() => {
    getLinks();
  }, []);

  const approvePayments = (isApproved) => {
    switch (isApproved) {
      case true:

        Api.approvePayments(selectedRows).then((res) => {
          setNotification({
            ...notification,
            open: true,
            message: 'The payments were approved!',
          });
          getLinks();
        })
          .catch((err) => {
            setNotification({
              ...notification,
              open: true,
              message: 'There was an error approving payments! Try again later!',
            });
          });
        break;
      case false:
        Api.disapprovePayments(selectedRows).then((res) => {
          setNotification({
            ...notification,
            open: true,
            message: 'The payments were disapproved!',
          });
          getLinks();
        })
          .catch((err) => {
            setNotification({
              ...notification,
              open: true,
              message: 'There was an error disapproving payments! Try again later!',
            });
          });

        break;

      default:
        break;
    }
    setIsDialogOpen(false);
  };

  const handleDialogOpen = (action) => {
    setIsDialogOpen(true);
    switch (action) {
      case 'approve':
        setDialogData({
          type: 'confirmation',
          title: 'Approve payments(s)',
          description: 'Approvind Payments will send a confirmation to the user. Make sure that this action is taken only after your bank confirmation is received.',
          buttons: {
            ok: {
              title: 'Confirm',
              action: () => approvePayments(true),
            },
          },
        });
        setIsDialogOpen(true);
        break;
      case 'disapprove':
        setDialogData({
          type: 'confirmation',
          title: 'Unapprove payments(s)',
          description: 'Unapproving ttransactinos will have consequences on all the stats and user interactions. Are you sure you want to proceed?',
          buttons: {
            ok: {
              title: 'Confirm',
              action: () => approvePayments(false),
            },
          },
        });
        setIsDialogOpen(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item"><a href="/#">Cases</a></li>
            <li className="breadcrumb-item active" aria-current="page">Payments Management</li>
          </ol>
          <h4 className="main-title mb-0">Payments Management</h4>
        </div>
        <div className="d-flex gap-2 mt-3 mt-md-0">
          <Button variant="" disabled={selectedRows ? selectedRows?.length === 0 : !checkPermission(permissions, 'case', 'edit')} onClick={() => handleDialogOpen('approve')}>
            <Tooltip title="Approve payment(s)">
              <CheckCircleIcon />
            </Tooltip>
          </Button>
          <Button variant="" disabled={selectedRows ? selectedRows?.length === 0 : !checkPermission(permissions, 'case', 'edit')} onClick={() => handleDialogOpen('disapprove')}>
            <Tooltip title="Disapprove payment(s)">
              <UnpublishedIcon />
            </Tooltip>
          </Button>
          {/* <Button variant="" disabled={selectedRows?.length === 0 || !checkPermission(permissions, 'case', 'delete')} onClick={() => handleDialogOpen('disapprove')}>
            <Tooltip title="Delete case(s)">
              <UnpublishedIcon />
            </Tooltip>
          </Button> */}
        </div>
      </div>

      <div className="col-12">
        <div className="card card-one logged">
          <DataGrid
            getRowId={(row) => row._id}
            rows={payments}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            onRowSelectionModelChange={(rows, details) => {
              setSelectedRows(rows);
            }}
          />
        </div>
      </div>

      <SdDialog
        data={dialogData}
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
      />

    </>
  );
}

export default Payments;
