import axios from 'axios';
import { sha256 } from 'js-sha256';

const configOptions = (token) => ({
  headers: {
    authorization: `Bearer ${token}`,
  },
});

const url = (path) => `${process.env.REACT_APP_API_SERVER}/${path}`;

const Api = {
  heartbeat: async () => axios.get(url('heartbeat'), configOptions(sessionStorage.getItem('token'))),
  login: async (user, pass) => axios.post(url('login'), {
    email: user,
    password: pass,
  }),

  getDashboard: async () => axios.get(url('dashboard'), configOptions(sessionStorage.getItem('token'))),

  getUsers: async () => axios.get(url('users'), configOptions(sessionStorage.getItem('token'))),
  getUser: async (userId) => axios.get(url(`user/${userId}`), configOptions(sessionStorage.getItem('token'))),
  setUser: async (user) => axios.post(url('user'), { ...user, password: sha256(user.password), isEnabled: user.isEnabled === 'on' }, configOptions(sessionStorage.getItem('token'))),
  updateUser: async (user) => axios.put(url('user'), { ...user, isEnabled: user.isEnabled === 'on' }, configOptions(sessionStorage.getItem('token'))),
  updateUserPassword: async (user) => axios.put(url('user'), { ...user, password: sha256(user.password) }, configOptions(sessionStorage.getItem('token'))),
  deleteUser: async (userId) => axios.delete(url(`user/${userId}`), configOptions(sessionStorage.getItem('token'))),
  deleteUsers: async (data) => axios.post(url('users/delete'), { ids: data }, configOptions(sessionStorage.getItem('token'))),

  getGroups: async () => axios.get(url('groups'), configOptions(sessionStorage.getItem('token'))),
  getGroup: async (groupId) => axios.get(url(`group/${groupId}`), configOptions(sessionStorage.getItem('token'))),
  setGroup: async (group) => axios.post(url('group'), group, configOptions(sessionStorage.getItem('token'))),
  updateGroup: async (group) => axios.put(url('group'), group, configOptions(sessionStorage.getItem('token'))),
  deleteGroup: async (groupId) => axios.delete(url(`group/${groupId}`), configOptions(sessionStorage.getItem('token'))),

  getAssistants: async () => axios.get(url('assistants'), configOptions(sessionStorage.getItem('token'))),
  getAssistant: async (data) => axios.get(url('assistants'), data, configOptions(sessionStorage.getItem('token'))),
  updateAssistant: async (data) => axios.get(url('assistants'), data, configOptions(sessionStorage.getItem('token'))),
  deleteAssistant: async (id) => axios.get(url(`assistantt/${id}`), configOptions(sessionStorage.getItem('token'))),

  getCompanies: async () => axios.get(url('companies'), configOptions(sessionStorage.getItem('token'))),
  getCompaniesSettings: async () => axios.get(url('companies/get-settings'), configOptions(sessionStorage.getItem('token'))),
  getCompany: async (companyId) => axios.get(url(`company/${companyId}`), configOptions(sessionStorage.getItem('token'))),
  setCompany: async (company) => axios.post(url('company'), { ...company, isEnabled: company.isEnabled === 'on' }, configOptions(sessionStorage.getItem('token'))),
  setCompaniesSettings: async (data) => axios.post(url('companies/set-settings'), data, configOptions(sessionStorage.getItem('token'))),
  updateCompany: async (company) => axios.put(url('company'), { ...company, isEnabled: company.isEnabled === 'on' }, configOptions(sessionStorage.getItem('token'))),
  deleteCompany: async (companyId) => axios.delete(url(`company/${companyId}`), configOptions(sessionStorage.getItem('token'))),

  uploadCases: async (data) => axios.post(
    url('import/cases'),
    data,
    {
      headers:
    {
      authorization: `Bearer ${sessionStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data',
    },
    },

  ),
  getImports: async () => axios.get(url('imports'), configOptions(sessionStorage.getItem('token'))),

  getCases: async () => axios.get(url('cases'), configOptions(sessionStorage.getItem('token'))),
  getCase: async (caseId) => axios.get(url(`case/${caseId}`), configOptions(sessionStorage.getItem('token'))),
  setCase: async (caseObject) => axios.post(url('case'), { ...caseObject, isActive: caseObject.isEnabled === 'on' }, configOptions(sessionStorage.getItem('token'))),
  updateCase: async (caseObject) => axios.put(url('case'), { ...caseObject, isActive: caseObject.isEnabled === 'on', isCompleted: caseObject.isCompleted === 'on' }, configOptions(sessionStorage.getItem('token'))),
  enableMultiple: async (data) => axios.post(url('cases/enable'), { ids: data }, configOptions(sessionStorage.getItem('token'))),
  deleteCase: async (caseId) => axios.delete(url(`case/${caseId}`), configOptions(sessionStorage.getItem('token'))),
  deleteCases: async (data) => axios.post(url('cases/delete'), { ids: data }, configOptions(sessionStorage.getItem('token'))),

  getLink: async (linkId) => axios.get(url(`link/${linkId}`), configOptions(sessionStorage.getItem('token'))),
  getLinks: async () => axios.get(url('links'), configOptions(sessionStorage.getItem('token'))),

  getCalls: async () => axios.get(url('calls'), configOptions(sessionStorage.getItem('token'))),

  getPayments: async () => axios.get(url('payments'), configOptions(sessionStorage.getItem('token'))),
  approvePayments: async (data) => axios.post(url('payments/approve'), data, configOptions(sessionStorage.getItem('token'))),
  disapprovePayments: async (data) => axios.post(url('payments/disapprove'), data, configOptions(sessionStorage.getItem('token'))),

  getChats: async () => axios.get(url('chats'), configOptions(sessionStorage.getItem('token'))),
  getChat: async (chatId) => axios.get(url(`case/${chatId}`), configOptions(sessionStorage.getItem('token'))),
  getChatBy: async (data) => axios.post(url('chats/get'), data, configOptions(sessionStorage.getItem('token'))),
  setChat: async (chat) => axios.post(url('case'), chat, configOptions(sessionStorage.getItem('token'))),
  updateChat: async (chat) => axios.put(url('case'), chat, configOptions(sessionStorage.getItem('token'))),
  deleteChat: async (chatId) => axios.delete(url(`case/${chatId}`), configOptions(sessionStorage.getItem('token'))),

  postAiMessage: async (data) => axios.post(url('chats/ai/message'), data),
  postDemoMessage: async (data) => axios.post(url('chats/demo/message'), data),

  getConfigs: async () => axios.get(url('configs'), configOptions(sessionStorage.getItem('token'))),
  getConfig: async (configId) => axios.get(url(`case/${configId}`), configOptions(sessionStorage.getItem('token'))),
  setConfig: async (config) => axios.post(url('case'), config, configOptions(sessionStorage.getItem('token'))),
  updateConfig: async (config) => axios.put(url('case'), config, configOptions(sessionStorage.getItem('token'))),
  deleteConfig: async (configId) => axios.delete(url(`case/${configId}`), configOptions(sessionStorage.getItem('token'))),

  getLogs: async () => axios.get(url('logs'), configOptions(sessionStorage.getItem('token'))),
  getLog: async (logId) => axios.get(url(`case/${logId}`), configOptions(sessionStorage.getItem('token'))),
  setLog: async (log) => axios.post(url('case'), log, configOptions(sessionStorage.getItem('token'))),
  updateLog: async (log) => axios.put(url('case'), log, configOptions(sessionStorage.getItem('token'))),
  deleteLog: async (logId) => axios.delete(url(`case/${logId}`), configOptions(sessionStorage.getItem('token'))),

  getHooks: async () => axios.get(url('hooks'), configOptions(sessionStorage.getItem('token'))),
  updateHooks: async (hooks) => axios.put(url('hooks'), { hooks: [hooks] }, configOptions(sessionStorage.getItem('token'))),

  getPaymentPageInfo: async (linkId) => axios.get(url(`payment/info/${linkId}`), configOptions(sessionStorage.getItem('token'))),

  validatePayment: async (data) => axios.post(url('payment'), data, configOptions(sessionStorage.getItem('token'))),
  updatePayment: async (data) => axios.put(url('payment'), data, configOptions(sessionStorage.getItem('token'))),

  demoLive: async (data) => axios.post(url('demo/live'), data, configOptions(sessionStorage.getItem('token'))),

  enforceCall: async (caseId) => axios.get(url(`stream/queue/enforce-call/${caseId}`), configOptions(sessionStorage.getItem('token'))),

};

export default Api;
