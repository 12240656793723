/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DataGrid } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SdDialog from '../../components/Dialog/Dialog';
import { NotificationContext, SecurityContext } from '../../Context';
import checkPermission from '../../helpers/helpers';
import './Company.scss';
import Api from '../../api/api';

const columns = [
  { field: '_id', headerName: 'Id', width: 70 },
  { field: 'name', headerName: 'Group Name', width: 130 },
  { field: 'email', headerName: 'Email', width: 130 },
  { field: 'phone', headerName: 'Phone', width: 130 },
  {
    field: 'isEnabled', headerName: 'Is Active', width: 130, valueGetter: ({ row }) => `${row.isEnabled ? 'Yes' : 'No'}`,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 130,
    valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY hh:mm A'),
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 130,
    valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY hh:mm A'),
  },
];

function Companies() {
  const { t } = useTranslation();
  const { permissions, setPermissions } = useContext(SecurityContext);

  const [dialogData, setDialogData] = useState();
  const [groups, setGroups] = useState([]);
  const [form, setForm] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [companies, setCompanies] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filtereddRows, setFilteredRows] = useState([]);
  const { notification, setNotification } = useContext(NotificationContext);

  const getCompanies = () => {
    Api.getCompanies().then((res) => {
      setCompanies(res.data.body);
    });
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const getEditValue = (id, field) => {
    console.log(id, field);
    const entry = companies.find((data) => data._id === id);
    switch (field) {
      case 'group':
        return entry[field].name;
      case 'company':
        return entry[field].name;
      default:
        return entry[field];
    }
  };

  const createEntry = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries((formData).entries());
    Api.setCompany(data).then((res) => {
      setNotification({
        ...notification,
        open: true,
        message: 'User successfully craeted!',
      });
      getCompanies();
    })
      .catch((err) => {
        setNotification({
          ...notification,
          open: true,
          message: err,
        });
      });
    setIsDialogOpen(false);
    getCompanies();
  };

  const updateEntry = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries((formData).entries());
    Api.updateCompany(data).then((res) => {
      setNotification({
        ...notification,
        open: true,
        message: 'User successfully craeted!',
      });
      getCompanies();
    })
      .catch((err) => {
        setNotification({
          ...notification,
          open: true,
          message: err,
        });
      });
    setIsDialogOpen(false);
    getCompanies();
  };

  const deleteCompany = () => {
    Api.deleteCompany(selectedRows).then((res) => {
      setNotification({
        ...notification,
        open: true,
        message: res.data.message,
      });
      getCompanies();
      setIsDialogOpen(false);
    })
      .catch((err) => {
        setNotification({
          ...notification,
          open: true,
          message: 'There was an error.',
        });
      });
  };

  const handleDialogOpen = (action) => {
    setIsDialogOpen(true);
    switch (action) {
      case 'add':
        setDialogData({
          type: 'form',
          title: 'Create company',
          description: '',
          fields: [
            {
              type: 'text',
              id: 'name',
              label: 'Company Name',
              defaultValue: null,
            },
            {
              type: 'email',
              id: 'email',
              label: 'Email',
              defaultValue: null,
            },
            {
              type: 'text',
              id: 'phone',
              label: 'Phone',
              defaultValue: null,
            },
            {
              type: 'text',
              id: 'address',
              label: 'Address',
              defaultValue: null,
            },
            {
              type: 'check',
              id: 'isEnabled',
              label: 'Is Active',
              defaultValue: null,
            },
          ],
          buttons: {
            ok: {
              title: 'Create',
              action: createEntry,
            },
          },
        });
        setIsDialogOpen(true);
        break;
      case 'edit':
        setDialogData({
          type: 'form',
          title: 'Update company',
          description: '',
          fields: [
            {
              type: 'hidden',
              id: 'userId',
              label: 'User Id',
              defaultValue: selectedRows[0],
            },
            {
              type: 'hidden',
              id: 'companyId',
              label: 'Company Id',
              defaultValue: getEditValue(selectedRows[0], '_id'),
            },
            {
              type: 'text',
              id: 'name',
              label: 'Company Name',
              defaultValue: getEditValue(selectedRows[0], 'name'),
            },
            {
              type: 'text',
              id: 'email',
              label: 'Email',
              defaultValue: getEditValue(selectedRows[0], 'email'),
            },
            {
              type: 'text',
              id: 'phone',
              label: 'Phone',
              defaultValue: getEditValue(selectedRows[0], 'phone'),
            },
            {
              type: 'text',
              id: 'address',
              label: 'Address',
              defaultValue: getEditValue(selectedRows[0], 'address'),
            },
            {
              type: 'check',
              id: 'isEnabled',
              label: 'Is Active',
              defaultValue: getEditValue(selectedRows[0], 'isEnabled'),
            },
          ],
          buttons: {
            ok: {
              title: 'Save',
              action: updateEntry,
            },
          },
        });
        setIsDialogOpen(true);
        break;
      case 'delete':
        setDialogData({
          type: 'confirmation',
          title: 'Delete company',
          description: 'Deleting the selected company is not reversible. Are you sure you want to delete the selected company?',
          buttons: {
            ok: {
              title: 'Confirm',
              action: () => deleteCompany(),
            },
          },
        });
        setIsDialogOpen(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item"><a href="/#">Companies Management</a></li>
            <li className="breadcrumb-item active" aria-current="page">Companies</li>
          </ol>
          <h4 className="main-title mb-0">Companies Management</h4>
        </div>
        <div className="d-flex gap-2 mt-3 mt-md-0">
          <Button variant="" onClick={() => handleDialogOpen('add')} disabled={!checkPermission(permissions, 'company', 'write')}>
            <Tooltip title="Add new user">
              <AddIcon />
            </Tooltip>
          </Button>
          <Button variant="" disabled={selectedRows?.length !== 1 || !checkPermission(permissions, 'company', 'edit')} onClick={() => handleDialogOpen('edit')}>
            <Tooltip title="Edit user">
              <EditIcon />
            </Tooltip>
          </Button>
          <Button variant="" disabled={selectedRows?.length === 0 || !checkPermission(permissions, 'company', 'delete')} onClick={() => handleDialogOpen('delete')}>
            <Tooltip title="Delete user(s)">
              <DeleteIcon />
            </Tooltip>
          </Button>
        </div>
      </div>

      <div className="col-12">
        <div className="card card-one logged">
          <DataGrid
            getRowId={(row) => row._id}
            rows={companies}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            onRowSelectionModelChange={(rows, details) => {
              setSelectedRows(rows);
            }}
          />
        </div>
      </div>

      <SdDialog
        data={dialogData}
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
      />

    </>
  );
}

export default Companies;
