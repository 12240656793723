/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useContext, useState } from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import { Height } from '@mui/icons-material';
import { NotificationContext, SecurityContext } from '../../Context';
import checkPermission from '../../helpers/helpers';
import './Cases.scss';
import Api from '../../api/api';
import SdDialog from '../../components/Dialog/Dialog';

const columns = [
  { field: '_id', headerName: 'Id', width: 70 },
  {
    field: 'isActive', headerName: 'Is Active', width: 130, valueGetter: ({ row }) => `${row.isActive ? 'Yes' : 'No'}`,
  },
  { field: 'firstName', headerName: 'First name', width: 130 },
  { field: 'lastName', headerName: 'Last name', width: 130 },
  { field: 'email', headerName: 'Email', width: 130 },
  { field: 'phone', headerName: 'Mobile', width: 130 },
  { field: 'dueDate', headerName: 'Overdue', width: 130 },
  { field: 'amount', headerName: 'Amount', width: 130 },
  { field: 'currency', headerName: 'Currency', width: 130 },
  { field: 'creditorCompanyName', headerName: 'Creditor Company', width: 130 },
  { field: 'debtorCompanyName', headerName: 'Debtor Company', width: 130 },
  {
    field: 'creditorPaymentLink',
    headerName: 'Payment Link',
    width: 450,
    renderCell: (row) => (
      row.creditorPaymentLink
    ),
  },
  { field: 'restreamInterval', headerName: 'Recontact (Days)', width: 175 },
  { field: 'callAgainTimes', headerName: 'Call Again Times', width: 175 },
  { field: 'callAgainInterval', headerName: 'Call Again Interval (Minutes)', width: 175 },
  { field: 'socialIG', headerName: 'Instagram', width: 130 },
  { field: 'invoiceDate', headerName: 'Invoice Date', width: 130 },
  {
    field: 'isCompleted', headerName: 'Is Completed', width: 130, valueGetter: ({ row }) => `${row.isCompleted ? 'Yes' : 'No'}`,
  },
  {
    field: 'deal',
    headerName: 'Closed Deal',
    width: 500,
    renderCell: (row) => (
      row.value?.amount ? (
        <div>
          Payment Agreement:
          {row.value?.amount}
          {' '}
          {row.value?.currency}
          /
          {row.value?.interval}
          {' '}
          for
          {' '}
          {row.value?.iterations}
          {' '}
          time(s)
        </div>
      ) : 'There is no closed deal.'
    ),
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 130,
    valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY hh:mm A'),
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 130,
    valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY hh:mm A'),
  },
];

function Cases() {
  const { permissions, setPermissions } = useContext(SecurityContext);
  const [cases, setCases] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filtereddRows, setFilteredRows] = useState([]);
  const [groups, setGroups] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [dialogData, setDialogData] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { notification, setNotification } = useContext(NotificationContext);

  const getCases = () => {
    Api.getCases().then((res) => {
      setCases(res.data.body);
    });

    Api.getCompanies().then((res) => {
      setCompanies(res.data.body);
    });
  };

  useEffect(() => {
    getCases();
  }, []);

  const deleteCase = () => {
    Api.deleteCases(selectedRows).then((res) => {
      setNotification({
        ...notification,
        open: true,
        message: res.data.message,
      });
      getCases();
      setIsDialogOpen(false);
    })
      .catch((err) => {
        setNotification({
          ...notification,
          open: true,
          message: 'There was an error.',
        });
      });
  };

  const enforceCall = () => {
    Api.enforceCall(selectedRows[0]).then((res) => {
      setNotification({
        ...notification,
        open: true,
        message: res.data.message,
      });
      getCases();
      setIsDialogOpen(false);
    })
      .catch((err) => {
        setNotification({
          ...notification,
          open: true,
          message: 'There was an error enforcing the call.',
        });
      });
  };

  useEffect(() => {
    getCases();
  }, [isDialogOpen]);

  const getEditValue = (id, field) => {
    const entry = cases.find((data) => data._id === id);
    switch (field) {
      case 'group':
        return entry[field].name;
      case 'company':
        return entry[field].name;
      default:
        return entry[field];
    }
  };

  const createEntry = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries((formData).entries());
    Api.setCase(data).then((res) => {
      setNotification({
        ...notification,
        open: true,
        message: 'Case successfully craeted!',
      });
      getCases();
    })
      .catch((err) => {
        setNotification({
          ...notification,
          open: true,
          message: err,
        });
      });
    setIsDialogOpen(false);
    getCases();
  };

  const updateEntry = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries((formData).entries());
    Api.updateCase(data).then((res) => {
      setNotification({
        ...notification,
        open: true,
        message: 'Case successfully updated!',
      });
      getCases();
    })
      .catch((err) => {
        setNotification({
          ...notification,
          open: true,
          message: err,
        });
      });
    setIsDialogOpen(false);
    getCases();
  };
  const enableCases = () => {
    Api.enableMultiple(selectedRows).then((res) => {
      setNotification({
        ...notification,
        open: true,
        message: 'Cases successfully enabled!',
      });
    })
      .catch((err) => {
        setNotification({
          ...notification,
          open: true,
          message: 'There was an error enabling cases!',
        });
      });
    setIsDialogOpen(false);
  };

  const handleDialogOpen = (action) => {
    setIsDialogOpen(true);
    switch (action) {
      case 'add':
        setDialogData({
          type: 'form',
          title: 'Create case',
          description: '',
          fields: [
            {
              type: 'text',
              id: 'firstName',
              label: 'First Name',
              defaultValue: null,
            },
            {
              type: 'text',
              id: 'lastName',
              label: 'Last Name',
              defaultValue: null,
            },
            {
              type: 'email',
              id: 'email',
              label: 'Email',
              defaultValue: null,
            },
            {
              type: 'text',
              id: 'phone',
              label: 'Mobile',
              defaultValue: null,
            },
            {
              type: 'text',
              id: 'socialFB',
              label: 'Facebook',
              defaultValue: null,
            },
            {
              type: 'text',
              id: 'socialIG',
              label: 'Instagram',
              defaultValue: null,
            },
            {
              type: 'text',
              id: 'socialTT',
              label: 'twitter',
              defaultValue: null,
            },
            {
              type: 'date',
              id: 'invoiceDate',
              label: 'Invoice Date',
              defaultValue: null,
            },
            {
              type: 'text',
              id: 'invoiceNumber',
              label: 'Invoice Number',
              defaultValue: null,
            },
            {
              type: 'text',
              id: 'externalCaseNumber',
              label: 'External case number',
              defaultValue: null,
            },
            {
              type: 'text',
              id: 'overdue',
              label: 'Overdue',
              defaultValue: null,
            },
            {
              type: 'text',
              id: 'amount',
              label: 'Amount',
              defaultValue: null,
            },
            {
              type: 'text',
              id: 'currency',
              label: 'Currency',
              defaultValue: null,
            },
            {
              type: 'autocomplete',
              id: 'company',
              label: 'Company',
              values: companies.map((company) => ({
                value: company._id,
                label: company.name,
              })),
              defaultValue: null,
            },
            {
              type: 'text',
              id: 'creditorPaymentLink',
              label: 'Payment Link',
              defaultValue: null,
            },
            {
              type: 'text',
              id: 'restreamInterval',
              label: 'Recall Interval (days)',
              defaultValue: null,
            },
            {
              type: 'text',
              id: 'callAgainTimes',
              label: 'Recall Number (number of times)',
              defaultValue: null,
            },
            {
              type: 'text',
              id: 'callAgainInterval',
              label: 'Recall Interval (minutes)',
              defaultValue: null,
            },
            {
              type: 'check',
              id: 'isActive',
              label: 'Is Active',
              defaultValue: null,
            },
            {
              type: 'check',
              id: 'isCompleted',
              label: 'Is Completed',
              defaultValue: null,
            },
          ],
          buttons: {
            ok: {
              title: 'Create',
              action: createEntry,
            },
          },
        });
        setIsDialogOpen(true);
        break;
      case 'edit':
        setDialogData({
          type: 'form',
          title: 'Update case',
          description: '',
          fields: [
            {
              type: 'hidden',
              id: 'caseId',
              label: 'Case Id',
              defaultValue: selectedRows[0],
            },
            {
              type: 'text',
              id: 'firstName',
              label: 'First Name',
              defaultValue: getEditValue(selectedRows[0], 'firstName'),
            },
            {
              type: 'text',
              id: 'lastName',
              label: 'Last Name',
              defaultValue: getEditValue(selectedRows[0], 'lastName'),
            },
            {
              type: 'email',
              id: 'email',
              label: 'Email',
              defaultValue: getEditValue(selectedRows[0], 'email'),
            },
            {
              type: 'text',
              id: 'phone',
              label: 'Mobile',
              defaultValue: getEditValue(selectedRows[0], 'phone'),
            },
            {
              type: 'text',
              id: 'socialFB',
              label: 'Facebook',
              defaultValue: getEditValue(selectedRows[0], 'socialFB'),
            },
            {
              type: 'text',
              id: 'socialIG',
              label: 'Instagram',
              defaultValue: getEditValue(selectedRows[0], 'socialIG'),
            },
            {
              type: 'text',
              id: 'socialTT',
              label: 'twitter',
              defaultValue: getEditValue(selectedRows[0], 'socialTT'),
            },
            {
              type: 'date',
              id: 'invoiceDate',
              label: 'Invoice Date',
              defaultValue: getEditValue(selectedRows[0], 'invoiceDate'),
            },
            {
              type: 'text',
              id: 'invoiceNumber',
              label: 'Invoice Number',
              defaultValue: getEditValue(selectedRows[0], 'invoiceNumber'),
            },
            {
              type: 'text',
              id: 'externalCaseNumber',
              label: 'External case number',
              defaultValue: getEditValue(selectedRows[0], 'externalCaseNumber'),
            },
            {
              type: 'text',
              id: 'overdue',
              label: 'Overdue',
              defaultValue: getEditValue(selectedRows[0], 'overdue'),
            },
            {
              type: 'text',
              id: 'maxRepayment',
              label: 'Max repayment period (in months)',
              defaultValue: getEditValue(selectedRows[0], 'maxRepayment'),
            },
            {
              type: 'text',
              id: 'amount',
              label: 'Amount',
              defaultValue: getEditValue(selectedRows[0], 'amount'),
            },
            {
              type: 'text',
              id: 'currency',
              label: 'Currency',
              defaultValue: getEditValue(selectedRows[0], 'currency'),
            },
            {
              type: 'autocomplete',
              id: 'company',
              label: 'Company',
              values: companies.map((company) => ({
                value: company._id,
                label: company.name,
              })),
              defaultValue: getEditValue(selectedRows[0], 'company'),
            },
            {
              type: 'text',
              id: 'creditorPaymentLink',
              label: 'Payment Link',
              defaultValue: getEditValue(selectedRows[0], 'creditorPaymentLink'),
            },
            {
              type: 'text',
              id: 'restreamInterval',
              label: 'Recall Interval (days)',
              defaultValue: getEditValue(selectedRows[0], 'restreamInterval'),
            },
            {
              type: 'text',
              id: 'callAgainTimes',
              label: 'Recall Number (number of times)',
              defaultValue: getEditValue(selectedRows[0], 'callAgainTimes'),
            },
            {
              type: 'text',
              id: 'callAgainInterval',
              label: 'Recall Interval (minutes)',
              defaultValue: getEditValue(selectedRows[0], 'callAgainInterval'),
            },
            {
              type: 'check',
              id: 'isEnabled',
              label: 'Is Active',
              disabled: getEditValue(selectedRows[0], 'isCompleted') || getEditValue(selectedRows[0], 'isActive'),
              defaultValue: getEditValue(selectedRows[0], 'isActive'),
            },
            {
              type: 'check',
              id: 'isCompleted',
              label: 'Is Completed',
              disabled: true,
              defaultValue: getEditValue(selectedRows[0], 'isCompleted'),
            },
          ],
          buttons: {
            ok: {
              title: 'Save',
              action: updateEntry,
            },
          },
        });
        setIsDialogOpen(true);
        break;
      case 'delete':
        setDialogData({
          type: 'confirmation',
          title: 'Delete case(s)',
          description: 'Deleting the selected cases is not reversible. Are you sure you want to delete the selected cases?',
          buttons: {
            ok: {
              title: 'Confirm',
              action: () => deleteCase(),
            },
          },
        });
        setIsDialogOpen(true);
        break;
      case 'forceCall':
        setDialogData({
          type: 'confirmation',
          title: 'Enforce user call',
          description: 'By proceeding, you will reset all the existing user flows and you will enfocrea new one starting the current initiated call. Are you sure you want to proceed?',
          buttons: {
            ok: {
              title: 'Confirm',
              action: () => enforceCall(),
            },
          },
        });
        setIsDialogOpen(true);
        break;
      case 'enableSelectedCases':
        setDialogData({
          type: 'confirmation',
          title: 'Enable selected case(s)',
          description: 'This operation is not reversible. Once you enable the cases, the users will be contacted by the AI chat agent, using the channles you selected.',
          buttons: {
            ok: {
              title: 'Confirm',
              action: () => enableCases(),
            },
          },
        });
        setIsDialogOpen(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item"><a href="/#">Cases</a></li>
            <li className="breadcrumb-item active" aria-current="page">Cases Management</li>
          </ol>
          <h4 className="main-title mb-0">Cases Management</h4>
        </div>
        <div className="d-flex gap-2 mt-3 mt-md-0">
          <Button variant="" onClick={() => handleDialogOpen('forceCall')} disabled={selectedRows?.length !== 1 || !checkPermission(permissions, 'company', 'write')}>
            <Tooltip title="Enforce User Call">
              <LocalPhoneIcon />
            </Tooltip>
          </Button>
          <Button variant="" onClick={() => handleDialogOpen('enableSelectedCases')} disabled={selectedRows?.length === 0 || !checkPermission(permissions, 'case', 'write')}>
            <Tooltip title="Enable selected cases">
              <SendIcon />
            </Tooltip>
          </Button>
          <Button variant="" onClick={() => handleDialogOpen('add')} disabled={!checkPermission(permissions, 'case', 'write')}>
            <Tooltip title="Add new case">
              <AddIcon />
            </Tooltip>
          </Button>
          <Button variant="" disabled={selectedRows?.length !== 1 || !checkPermission(permissions, 'case', 'edit')} onClick={() => handleDialogOpen('edit')}>
            <Tooltip title="Edit case">
              <EditIcon />
            </Tooltip>
          </Button>
          <Button variant="" disabled={selectedRows?.length === 0 || !checkPermission(permissions, 'case', 'delete')} onClick={() => handleDialogOpen('delete')}>
            <Tooltip title="Delete case(s)">
              <DeleteIcon />
            </Tooltip>
          </Button>
        </div>
      </div>

      <div className="col-12">
        <div className="card card-one logged">
          <DataGrid
            getRowId={(row) => row._id}
            rows={cases}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            onRowSelectionModelChange={(rows, details) => {
              setSelectedRows(rows);
            }}
          />
        </div>
      </div>

      <SdDialog
        data={dialogData}
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
      />

    </>
  );
}

export default Cases;
