/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Button, Grid, Input, InputLabel, Select, MenuItem,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import PasswordIcon from '@mui/icons-material/Password';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import { NotificationContext, SecurityContext } from '../../Context';
import Api from '../../api/api';
import SdDialog from '../../components/Dialog/Dialog';
import checkPermission from '../../helpers/helpers';
import './Users.scss';

const columns = [
  { field: '_id', headerName: 'Id', width: 70 },
  { field: 'firstName', headerName: 'First name', width: 130 },
  { field: 'lastName', headerName: 'Last name', width: 130 },
  { field: 'email', headerName: 'Email', width: 130 },
  { field: 'mobile', headerName: 'Mobile', width: 130 },
  {
    field: 'group',
    headerName: 'Group',
    width: 130,
    valueGetter: (group) => group?.value?.name,
  },
  {
    field: 'company', headerName: 'Company', width: 130, valueGetter: (company) => company?.value?.name,
  },
  {
    field: 'isEnabled', headerName: 'Is Active', width: 130, valueGetter: ({ row }) => `${row.isEnabled ? 'Yes' : 'No'}`,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 130,
    valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY hh:mm A'),
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 130,
    valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY hh:mm A'),
  },
];

function Users() {
  const { t } = useTranslation();
  const { permissions, setPermissions } = useContext(SecurityContext);
  const [users, setUsers] = useState([]);
  const [dialogData, setDialogData] = useState();
  const [groups, setGroups] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [form, setForm] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const { notification, setNotification } = useContext(NotificationContext);

  const getUsers = () => {
    Api.getUsers().then((res) => {
      setUsers(res.data.body);
    });
  };

  const deleteUser = () => {
    Api.deleteUsers(selectedRows).then((res) => {
      setNotification({
        ...notification,
        open: true,
        message: res.data.message,
      });
      getUsers();
      setIsDialogOpen(false);
    })
      .catch((err) => {
        setNotification({
          ...notification,
          open: true,
          message: 'There was an error.',
        });
      });
  };

  useEffect(() => {
    Api.getGroups().then((res) => {
      console.log(res.data.body);
      setGroups(res.data.body);
    });

    Api.getCompanies().then((res) => {
      setCompanies(res.data.body);
    });
    getUsers();
  }, []);

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const getEditValue = (id, field) => {
    console.log(id, field);
    const entry = users.find((data) => data._id === id);
    switch (field) {
      case 'group':
        return entry[field].name;
      case 'company':
        return entry[field].name;
      default:
        return entry[field];
    }
  };

  const createEntry = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries((formData).entries());
    Api.setUser(data).then((res) => {
      setNotification({
        ...notification,
        open: true,
        message: 'User successfully craeted!',
      });
      getUsers();
    })
      .catch((err) => {
        setNotification({
          ...notification,
          open: true,
          message: err,
        });
      });
    setIsDialogOpen(false);
    getUsers();
  };

  const updateEntry = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries((formData).entries());
    Api.updateUser(data).then((res) => {
      setNotification({
        ...notification,
        open: true,
        message: 'User successfully craeted!',
      });
      getUsers();
    })
      .catch((err) => {
        setNotification({
          ...notification,
          open: true,
          message: err,
        });
      });
    setIsDialogOpen(false);
    getUsers();
  };

  const updatePassword = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries((formData).entries());
    if (data.password !== data.repassword) {
      setNotification({
        ...notification,
        open: true,
        message: 'The passwords do not match!',
      });
      return;
    }
    Api.updateUserPassword(data).then((res) => {
      setNotification({
        ...notification,
        open: true,
        message: 'Password successfully updated',
      });
      getUsers();
    })
      .catch((err) => {
        setNotification({
          ...notification,
          open: true,
          message: err,
        });
      });
    setIsDialogOpen(false);
    getUsers();
  };

  const handleDialogOpen = (action) => {
    setIsDialogOpen(true);
    switch (action) {
      case 'add':
        setDialogData({
          type: 'form',
          title: 'Create user',
          description: '',
          fields: [
            {
              type: 'text',
              id: 'firstName',
              label: 'First Name',
              defaultValue: null,
            },
            {
              type: 'text',
              id: 'lastName',
              label: 'Last Name',
              defaultValue: null,
            },
            {
              type: 'email',
              id: 'email',
              label: 'Email',
              defaultValue: null,
            },
            {
              type: 'password',
              id: 'password',
              label: 'Password',
              defaultValue: null,
            },
            {
              type: 'password',
              id: 'repassword',
              label: 'Retype password',
              defaultValue: null,
            },
            {
              type: 'text',
              id: 'mobile',
              label: 'Mobile',
              defaultValue: null,
            },
            {
              type: 'autocomplete',
              id: 'group',
              label: 'Group',
              values: groups.map((group) => ({
                value: group._id,
                label: group.name,
              })),
              defaultValue: null,
            },
            {
              type: 'autocomplete',
              id: 'company',
              label: 'Company',
              values: companies.map((company) => ({
                value: company._id,
                label: company.name,
              })),
              defaultValue: null,
            },
            {
              type: 'check',
              id: 'isEnabled',
              label: 'Is Active',
              defaultValue: null,
            },
          ],
          buttons: {
            ok: {
              title: 'Create',
              action: createEntry,
            },
          },
        });
        setIsDialogOpen(true);
        break;
      case 'edit':
        setDialogData({
          type: 'form',
          title: 'Update user',
          description: '',
          fields: [
            {
              type: 'hidden',
              id: 'userId',
              label: 'User Id',
              defaultValue: selectedRows[0],
            },
            {
              type: 'text',
              id: 'firstName',
              label: 'First Name',
              defaultValue: getEditValue(selectedRows[0], 'firstName'),
            },
            {
              type: 'text',
              id: 'lastName',
              label: 'Last Name',
              defaultValue: getEditValue(selectedRows[0], 'lastName'),
            },
            {
              type: 'email',
              id: 'email',
              label: 'Email',
              defaultValue: getEditValue(selectedRows[0], 'email'),
            },
            {
              type: 'text',
              id: 'mobile',
              label: 'Mobile',
              defaultValue: getEditValue(selectedRows[0], 'mobile'),
            },
            {
              type: 'autocomplete',
              id: 'group',
              label: 'Group',
              values: groups.map((group) => ({
                value: group._id,
                label: group.name,
              })),
              defaultValue: getEditValue(selectedRows[0], 'group'),
            },
            {
              type: 'autocomplete',
              id: 'company',
              label: 'Company',
              values: companies.map((company) => ({
                value: company._id,
                label: company.name,
              })),
              defaultValue: getEditValue(selectedRows[0], 'company'),
            },
            {
              type: 'check',
              id: 'isEnabled',
              label: 'Is Active',
              defaultValue: getEditValue(selectedRows[0], 'isEnabled'),
            },
          ],
          buttons: {
            ok: {
              title: 'Save',
              action: updateEntry,
            },
          },
        });
        setIsDialogOpen(true);
        break;
      case 'password':
        setDialogData({
          type: 'form',
          title: 'Change password',
          description: '',
          fields: [
            {
              type: 'hidden',
              id: 'userId',
              label: 'User Id',
              defaultValue: selectedRows[0],
            },
            {
              type: 'password',
              id: 'password',
              label: 'New password',
              defaultValue: getEditValue(selectedRows[0], 'password'),
            },
            {
              type: 'password',
              id: 'repassword',
              label: 'Confirm password',
              defaultValue: getEditValue(selectedRows[0], 'password'),
            },
          ],
          buttons: {
            ok: {
              title: 'Update',
              action: updatePassword,
            },
          },
        });
        setIsDialogOpen(true);
        break;
      case 'delete':
        setDialogData({
          type: 'confirmation',
          title: 'Delete user(s)',
          description: 'Deleting the selected users is not reversible. Are you sure you want to delete the selected users?',
          buttons: {
            ok: {
              title: 'Confirm',
              action: () => deleteUser(),
            },
          },
        });
        setIsDialogOpen(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item"><a href="/#">User Management</a></li>
            <li className="breadcrumb-item active" aria-current="page">Users</li>
          </ol>
          <h4 className="main-title mb-0">User Management</h4>
        </div>

        <div className="d-flex gap-2 mt-3 mt-md-0">
          <Button variant="" onClick={() => handleDialogOpen('add')} disabled={!checkPermission(permissions, 'user', 'write')}>
            <Tooltip title="Add new user">
              <AddIcon />
            </Tooltip>
          </Button>
          <Button variant="" disabled={selectedRows?.length !== 1 || !checkPermission(permissions, 'user', 'edit')} onClick={() => handleDialogOpen('edit')}>
            <Tooltip title="Edit user">
              <EditIcon />
            </Tooltip>
          </Button>
          <Button variant="" disabled={selectedRows?.length !== 1 || !checkPermission(permissions, 'user', 'edit')} onClick={() => handleDialogOpen('password')}>
            <Tooltip title="Change Password">
              <PasswordIcon />
            </Tooltip>
          </Button>
          <Button variant="" disabled={selectedRows?.length === 0 || !checkPermission(permissions, 'user', 'delete')} onClick={() => handleDialogOpen('delete')}>
            <Tooltip title="Delete user(s)">
              <DeleteIcon />
            </Tooltip>
          </Button>
        </div>
      </div>

      <div className="col-12">
        <div className="card card-one logged">
          <DataGrid
            getRowId={(row) => row._id}
            rows={users}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            onRowSelectionModelChange={(rows) => {
              setSelectedRows(rows);
            }}
          />
        </div>
      </div>

      <SdDialog
        data={dialogData}
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
      />
    </>
  );
}

export default Users;
