/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { deepOrange } from '@mui/material/colors';

const useStyles = makeStyles(() => createStyles({
  messageRow: {
    display: 'flex',
    paddingTop: '15px',
  },
  messageRowRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '15px',
    flexDirection: 'column',
    textAlign: 'right',
  },
  messageBlue: {
    position: 'relative',
    marginLeft: '20px',
    marginBottom: '10px',
    padding: '10px',
    backgroundColor: '#A8DDFD',
    width: '60%',
    // height: "50px",
    textAlign: 'left',
    font: "400 .9em 'Open Sans', sans-serif",
    border: '1px solid #97C6E3',
    borderRadius: '10px',
    '&:after': {
      content: "''",
      position: 'absolute',
      width: '0',
      height: '0',
      borderTop: '15px solid #A8DDFD',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      top: '0',
      left: '-15px',
    },
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '0',
      height: '0',
      borderTop: '17px solid #97C6E3',
      borderLeft: '16px solid transparent',
      borderRight: '16px solid transparent',
      top: '-1px',
      left: '-17px',
    },
  },
  messageOrange: {
    position: 'relative',
    marginRight: '20px',
    marginBottom: '10px',
    padding: '10px',
    backgroundColor: '#f8e896',
    width: '60%',
    // height: "50px",
    textAlign: 'left',
    font: "400 .9em 'Open Sans', sans-serif",
    border: '1px solid #dfd087',
    borderRadius: '10px',
    '&:after': {
      content: "''",
      position: 'absolute',
      width: '0',
      height: '0',
      borderTop: '15px solid #f8e896',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      top: '0',
      right: '-15px',
    },
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '0',
      height: '0',
      borderTop: '17px solid #dfd087',
      borderLeft: '16px solid transparent',
      borderRight: '16px solid transparent',
      top: '-1px',
      right: '-17px',
    },
  },

  messageContent: {
    padding: 0,
    margin: 0,
  },
  messageTimeStampRight: {
    position: 'absolute',
    fontSize: '.85em',
    fontWeight: '300',
    marginTop: '10px',
    bottom: '-20px',
    right: '5px',
  },

  orange: {
    color: 'orange',
    backgroundColor: deepOrange[500],
    width: '400px',
    height: '400px',
  },
  avatarNothing: {
    color: 'transparent',
    backgroundColor: 'transparent',
    width: '400px',
    height: '400px',
  },
  displayName: {
    marginLeft: '20px',
  },
  displayNameRight: {
    marginRight: '20px',
  },
  rigtWrapper: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
}));

export function MessageLeft(props) {
  const message = props.message ? props.message : 'no message';
  const timestamp = props.timestamp ? props.timestamp : '';
  const displayName = props.displayName ? props.displayName : '';
  const classes = useStyles();
  return (
    <div className={classes.messageRow}>

      <div>
        <div className={classes.displayName}>{displayName}</div>
        <div className={classes.messageBlue}>
          <div>
            <p className={classes.messageContent}>{message}</p>
          </div>
          <div className={classes.messageTimeStampRight}>{timestamp}</div>
        </div>
      </div>
    </div>
  );
}

export function MessageRight(props) {
  const classes = useStyles();
  const message = props.message ? props.message : 'no message';
  const timestamp = props.timestamp ? props.timestamp : '';

  return (
    <div className={classes.messageRowRight}>
      <div className={classes.displayNameRight}>{props.displayName}</div>
      <div className={classes.rigtWrapper}>
        <div className={classes.messageOrange}>
          <p className={classes.messageContent}>{message}</p>
          <div className={classes.messageTimeStampRight}>{timestamp}</div>
        </div>
      </div>
    </div>
  );
}
