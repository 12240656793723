/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AppHeader from './components/Header/Header';
import AppMenu from './components/Menu/Menu';
import { SecurityContext, NotificationContext } from './Context';
import Api from './api/api';
import './App.scss';

function AppFrame() {
  const navigate = useNavigate();
  const token = sessionStorage.getItem('token');
  const [permissions, setPermissions] = useState({});
  const [notification, setNotification] = useState({
    open: false,
    message: '',
  });

  useEffect(() => {
    if (token) {
      Api.heartbeat().then((res) => {
        setPermissions(res.data);
      })
        .catch(() => {
          sessionStorage.removeItem('token');
          navigate('/');
        });
    } else {
      navigate('/');
    }
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotification({ ...notification, open: false });
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    permissions && (
    <SecurityContext.Provider value={{ permissions, setPermissions }}>
      <NotificationContext.Provider value={{ notification, setNotification }}>
        <AppMenu />

        <AppHeader />

        <div className="main main-app p-3 p-lg-4 logged">
          <Outlet />

          <Snackbar
            open={notification.open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            autoHideDuration={3000}
            onClose={() => setNotification({ ...notification, open: false })}
            message={notification.message}
            action={action}
          />
        </div>

        <script src="./assets/js/script.js" />
      </NotificationContext.Provider>
    </SecurityContext.Provider>
    )
  );
}

export default AppFrame;
