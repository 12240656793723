/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import './Invoices.scss';

function Invoices() {
  return (
    <>
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item"><a href="/#">Billing</a></li>
            <li className="breadcrumb-item active" aria-current="page">Invoices</li>
          </ol>
          <h4 className="main-title mb-0">Invoices</h4>
        </div>
        <div className="d-flex gap-2 mt-3 mt-md-0">
          {/* <button type="button" className="btn btn-white d-flex align-items-center gap-2">
            <i className="ri-share-line fs-18 lh-1" />
            Share
          </button>
          <button type="button" className="btn btn-white d-flex align-items-center gap-2">
            <i className="ri-printer-line fs-18 lh-1" />
            Print
          </button> */}
          <button type="button" className="btn btn-primary d-flex align-items-center gap-2">
            <i className="ri-bar-chart-2-line fs-18 lh-1" />
            Generate
            <span className="d-none d-sm-inline"> Report</span>
          </button>
        </div>
      </div>
      <div className="col-12">
        <div className="card card-one logged">
          Invoices
        </div>
      </div>
    </>
  );
}

export default Invoices;
