/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useRef } from 'react';
import './PaymentPage.scss';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import SdDialog from '../../components/Dialog/Dialog';
import Api from '../../api/api';

function PersonalAssistant() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { linkId } = useParams();

  const [data, setData] = useState();
  const [batch, setBatch] = useState(null);
  const [isLoading, setIsLoading] = useState();
  const [dialogData, setDialogData] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDealDone, setIsDealDone] = useState(false);

  const getRemainingDebt = () => {
    const paid = _.sum(data?.payment.map((item) => item.amount));
    return data?.debt - paid;
  };

  const getDealSegment = (amount, iterations) => {
    // if is the last payment, calculate w/o rounding by diff
    if (data?.deal?.iterations - data?.payment?.length === 1) {
      return getRemainingDebt();
    }
    return Math.ceil(parseFloat(amount / iterations, 10));
  };

  const getPageInfo = () => {
    Api.getPaymentPageInfo(linkId).then((res) => {
      if (res.data.body.length === 0) {
        navigate('/');
      }
      setBatch(getDealSegment(res.data.body?.debt, res.data.body?.deal?.iterations));
      setData(res.data.body);
      if (res.data?.body.deal) {
        setIsDealDone(true);
      }
    });
  };

  const updatePayment = () => {
    Api.updatePayment({
      id: data?.caseId,
      amount: !isDealDone ? data?.debt : data.deal?.iterations - data.payment.length === 1 ? data.deal.amount - _.sum(data?.payment.map((item) => item.amount)) : batch,
      currency: data?.currency,
      isConfirmed: true,
    }).then((res) => {
      getPageInfo();
      setIsDialogOpen(false);
    })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    // get the user and case data
    getPageInfo();
  }, []);

  useEffect(() => {
    setDialogData({
      type: 'modal',
      title: 'Confirm payment',
      description: 'Thank you for submitting the payment for confirmation! <br/>By pressing <b>confirm</b>, you confirm that the payment was submitted!',
      buttons: {
        ok: {
          title: 'Confirm',
          action: () => updatePayment(),
        },
      },
    });
  }, [batch]);

  return (
    <>
      <div className="back" />
      <div className="leaf">
        <div className="title">
          {data?.companyName}
        </div>
        <div className="content">
          <div className="left">
            Bank details:
            <br />
            <br />
            {data?.bankName}
            <br />
            Account holder:
            {' '}
            {data?.bankCompanyName}
            <br />
            SWIFT:
            {' '}
            {data?.bankSWIFT}
            <br />
            BIC:
            {' '}
            {data?.bankBIC}
            <br />
            Account:
            {' '}
            {data?.bankAccount}
            <br />
            <br />
            <b>
              Current amount to be paid:
              {' '}
              {(!isDealDone && data?.payment.length === 0) && data?.debt}
              {(!isDealDone && data?.payment.length === 1) && '0'}
              {data?.deal?.iterations - data?.payment?.length === 0 && '0'}
              {data?.deal?.iterations - data?.payment?.length > 1 && Math.ceil(parseFloat(data?.debt / data?.deal?.iterations, 10))}
              {data?.deal?.iterations - data?.payment?.length === 1 && getDealSegment(data?.debt, data?.deal?.iterations) }
              {' '}
              {data?.currency}
            </b>
            <br />
            <br />
            <i><b>And include reference</b></i>
            :
            {' '}
            {data?.caseId}
            <br />
            <br />
            {data?.paymentLink && (<>OR pay it by card, using this link:</>)}
            <br />
            {' '}
            {data?.paymentLink && (<a href={data?.paymentLink} target="_blank" rel="noreferrer">CLICK HERE TO REDIRECT TO THE BANK PAGE</a>)}
          </div>
          <div className="right">
            Total Overdue:
            {' '}
            {data?.debt}
            {' '}
            {data?.currency}
            <br />
            Remaining Debt:
            {' '}
            {getRemainingDebt()}
            {' '}
            {data?.currency}
            <br />
            <br />
            { isDealDone && data.deal && 'Deal agreed:'}
            {' '}
            {data?.deal?.iterations}
            {' '}
            { isDealDone && data.deal && 'batche(s)'}
            {/* of
            {' '}
            {getDealSegment(data?.debt, data?.deal.iterations)}
            {' '}
            {data?.currency}
            */}
            <br />
            <br />
            { data?.payment.length > 0 && 'Payment history:'}
            <br />
            <ul>
              {data?.payment.map((pay) => (
                <li>
                  <div className="left">
                    {moment(pay.createdAt).format('MMM Do YY')}
                    {' '}
                    {pay.amount}
                    {' '}
                    {pay.currency}
                  </div>
                  <div className="right">
                    {pay.isConfirmed ? 'confirmed' : 'pending'}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="content separator small">
          Please do press the button ONLY once paid to declare the payment.
          <br />
          <br />
          We will send a notification to
          {' '}
          {data?.creditorCompanyName}
          {' '}
          to verify and your case will be closed automatically.
          Please do press the button ONLY once paid to declare the payment.
          <br />
          <br />
          *IMPORTANT* IN CASE OF FAKE DECLARATION WE RESERVE THE RIGHT IMPOSE A PENALTY.
          <br />
        </div>
        <div className="content center separator">
          <Button variant="outlined" onClick={() => setIsDialogOpen(true)} disabled={getRemainingDebt() === 0}>Confirm payment</Button>
        </div>
      </div>
      <SdDialog
        data={dialogData}
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
      />
    </>
  );
}

export default PersonalAssistant;
