import React from 'react';

const menu = [{
  title: 'Dashboard',
  permissionKey: 'dashboard',
  isExpanded: true,
  items: [
    {
      name: 'General',
      permissionKey: 'dashboard',
      permissionAction: 'read',
      icon: <i className="ri-pie-chart-2-fill" />,
      link: '/account/dashboard',
    },
  ],
},
{
  title: 'User Management',
  isExpanded: true,
  permissionKey: 'user',
  permissionAction: 'read',
  items: [
    {
      name: 'Users',
      permissionKey: 'user',
      permissionAction: 'read',
      icon: <i className="ri-user-fill" />,
      link: '/account/user-management/users',
    },
    {
      name: 'Groups',
      permissionKey: 'group',
      permissionAction: 'read',
      icon: <i className="ri-group-fill" />,
      link: '/account/user-management/groups',
    },
  ],
},
{
  title: 'Assistants',
  isExpanded: true,
  permissionKey: 'company',
  permissionAction: 'read',
  items: [
    {
      name: 'Assistants',
      permissionKey: 'company',
      permissionAction: 'write',
      icon: <i className="ri-building-fill" />,
      link: '/account/assistants',
    },
  ],
},
{
  title: 'Company',
  isExpanded: true,
  permissionKey: 'company',
  permissionAction: 'read',
  items: [
    {
      name: 'Companies',
      permissionKey: 'company',
      permissionAction: 'write',
      icon: <i className="ri-building-fill" />,
      link: '/account/companies',
    },
    {
      name: 'Settings',
      permissionKey: 'company',
      permissionAction: 'read',
      icon: <i className="ri-file-settings-fill" />,
      link: '/account/companies/settings',
    },
  ],
},
{
  title: 'Cases',
  isExpanded: true,
  permissionKey: 'case',
  permissionAction: 'read',
  items: [
    {
      name: 'Cases',
      permissionKey: 'case',
      permissionAction: 'read',
      icon: <i className="ri-file-list-fill" />,
      link: '/account/cases',
    },
    {
      name: 'Imports',
      permissionKey: 'case',
      permissionAction: 'write',
      icon: <i className="ri-upload-fill" />,
      link: '/account/imports',
    },
    {
      name: 'Links',
      permissionKey: 'case',
      permissionAction: 'read',
      icon: <i className="ri-links-line" />,
      link: '/account/cases/links',
    },
    {
      name: 'Calls History',
      permissionKey: 'case',
      permissionAction: 'read',
      icon: <i className="ri-customer-service-fill" />,
      link: '/account/cases/calls',
    },
    {
      name: 'Payments',
      permissionKey: 'case',
      permissionAction: 'read',
      icon: <i className="ri-bank-card-fill" />,
      link: '/account/cases/payments',
    },
  ],
},
{
  title: 'Billing',
  isExpanded: true,
  permissionKey: 'billing',
  permissionAction: 'read',
  items: [
    {
      name: 'Billing',
      permissionKey: 'billing',
      permissionAction: 'read',
      icon: <i className="ri-pie-chart-2-fill" />,
      link: '/account/billing',
    },
    {
      name: 'Invoices',
      permissionKey: 'billing',
      permissionAction: 'read',
      icon: <i className="ri-pie-chart-2-fill" />,
      link: '/account/billing/invoices',
    },
  ],
},
{
  title: 'Configuration',
  isExpanded: true,
  permissionKey: 'billing',
  permissionAction: 'write',
  items: [
    {
      name: 'Hooks',
      permissionKey: 'billing',
      permissionAction: 'write',
      icon: <i className="ri-article-line" />,
      link: '/account/configuration/hooks',
    },
  ],
},
];

export default menu;
