/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useContext, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SecurityContext } from '../../Context';
import Logo from '../../assets/img/logo_white.png';
import './Menu.scss';
import menu from '../../mock/menu';

function AppMenu() {
  const { t } = useTranslation();

  const [visibleMenu, setVisibleMenu] = useState();
  const { permissions, setPermissions } = useContext(SecurityContext);

  const checkCategoryPermission = (key) => {
    let hasPermisison = false;
    if (Object.keys(permissions).length > 0) {
      permissions.permissions.forEach((permission) => {
        if (permission.object === key) hasPermisison = true;
      });
    }
    return hasPermisison;
  };

  const checkMenuPermission = (key, action) => {
    let hasPermisison = false;
    if (Object.keys(permissions).length > 0) {
      permissions.permissions.forEach((permission) => {
        if (permission.object === key) {
          if (permission.permissions[action] === true) {
            hasPermisison = true;
          }
        }
      });
    }
    return hasPermisison;
  };

  const buildMenu = () => {
    const pMenu = [];
    menu.forEach((category) => {
      const pMenuItems = [];
      if (checkCategoryPermission(category.permissionKey)) {
        category.items.forEach((item) => {
          if (checkMenuPermission(item.permissionKey, item.permissionAction)) {
            pMenuItems.push(item);
          }
        });
        if (pMenuItems.length > 0) {
          pMenu.push({
            title: category.title,
            isExpanded: true,
            items: pMenuItems,
          });
        }
      }
    });
    setVisibleMenu(pMenu);
  };

  useEffect(() => {
    buildMenu();
  }, [permissions]);

  return (
    <PerfectScrollbar>
      <div className="sidebar">
        <div className="sidebar-header">
          <Link to="/dashboard">
            <img src={Logo} alt="SansDebt logo" width={125} />
          </Link>

        </div>
        <div id="sidebarMenu" className="sidebar-body">
          {visibleMenu && visibleMenu.map((category, index) => (
            <div className={category.isExpanded ? 'nav-group show' : 'nav-group'} key={category.title}>
              <div className="nav-label">{category.title}</div>
              <ul className="nav nav-sidebar">
                {category.items.map((item) => (
                  <li className="nav-item" key={item.name}>
                    <Link to={item.link} className="nav-link">
                      { item.icon }
                      {' '}
                      <span>{ item.name }</span>
                    </Link>
                  </li>
                ))}

              </ul>
            </div>
          ))}

        </div>
      </div>
    </PerfectScrollbar>
  );
}

export default AppMenu;
