/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { sha256 } from 'js-sha256';
import './Login.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from '../../assets/img/logo_white.png';
import Api from '../../api/api';

function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState('');
  const [pass, setPass] = useState('');

  const handleLogin = () => {
    setIsLoading(true);
    if (user !== null && pass !== null) {
      Api.login(user, sha256(pass)).then((res) => {
        if (res.data.status === 'ok') {
          sessionStorage.setItem('token', res.data.message);
          navigate('/dashboard');
        }
      }).catch((err) => console.error(err));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (sessionStorage.getItem('token')) {
      navigate('/account/dashboard');
    }
  }, []);

  const keySumbmit = (e) => {
    if (e.code === 'Enter') {
      handleLogin();
    }
  };

  return (
    <div className="row g-0 login">
      <div className="col-md-7 col-lg-5 col-xl-4 col-wrapper">
        <div className="card card-sign">
          <div className="card-header">
            <a href="../" className="">
              <img src={Logo} alt="SansDebt logo" width={325} />
            </a>
            {/* <h3 className="card-title">Sign In</h3> */}
            <p className="card-text">
              {t('login_welcome_back')}
            </p>
          </div>
          <div className="card-body">
            <div className="mb-4">
              <label className="form-label">{t('email_address')}</label>
              <input type="text" className="form-control" placeholder={t('enter_email')} value={user} onChange={(e) => setUser(e.target.value)} onKeyDown={(e) => keySumbmit(e)} />
            </div>
            <div className="mb-4">
              <label className="form-label d-flex justify-content-between">
                {t('password')}
                <a href="/">{t('foregot_pass')}</a>
              </label>
              <input type="password" className="form-control" placeholder={t('enter_pass')} value={pass} onChange={(e) => setPass(e.target.value)} onKeyDown={(e) => keySumbmit(e)} />
            </div>
            <button className="btn btn-primary btn-sign" onClick={() => handleLogin()} disabled={isLoading}>{t('sign_in')}</button>

          </div>
          <div className="card-footer">
            {t('login_contact_message')}
            <a href="https://www.sansdebt.com/application-support" target="_blank" rel="noreferrer">
              {' '}
              {t('login_contact_link_text')}
            </a>
            .
          </div>
        </div>
      </div>
      <div className="col d-none d-lg-block"><img src="../assets/img/login-back.jpg" className="auth-img" alt="" /></div>
    </div>
  );
}

export default Login;
