/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import { SecurityContext } from '../../Context';
import './Header.scss';

function AppHeader() {
  const navigate = useNavigate();
  const { permissions, setPermissions } = useContext(SecurityContext);

  const [isSidebarClosed, setIsSidebarClosed] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('token');
    handleClose();
    navigate('/');
  };

  const toggleMenu = () => {
    if (isSidebarClosed) {
      if (window.innerWidth > 992) {
        document.body.classList.remove('sidebar-hide');
        setIsSidebarClosed(!isSidebarClosed);
      } else {
        // for mobile < 992
        document.body.classList.remove('sidebar-show');
        document.body.classList.remove('sidebar-offset');
        setIsSidebarClosed(!isSidebarClosed);
      }
    } else if (window.innerWidth > 992) {
      document.body.classList.add('sidebar-hide');
      setIsSidebarClosed(!isSidebarClosed);
    } else {
      // for mobile < 992
      document.body.classList.add('sidebar-show');
      document.body.classList.add('sidebar-offset');
      setIsSidebarClosed(!isSidebarClosed);
    }
  };
  return (
    <div className="header-main px-3 px-lg-4">
      <button id="menuSidebar" className="menu-link me-3 me-lg-4" onClick={() => toggleMenu()}>
        <i className="ri-menu-2-fill" />
      </button>

      <div className="me-auto" />

      <div className="dropdown dropdown-profile ms-3 ms-xl-4">
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          {/* <AccountCircleIcon color="primary" /> */}
          {' '}
          Hi,
          {' '}
          {permissions.name}
          !
          {' '}
          <ArrowDropDownIcon color="primary" />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </div>
    </div>
  );
}

export default AppHeader;
