/* eslint-disable react/destructuring-assignment */
import React from 'react';

export function MessageLeft(props) {
  const message = props.message ? props.message : 'no message';
  const timestamp = props.timestamp ? props.timestamp : '';
  return (
    <div className="msg-item">
      <div className="avatar online"><img src="../assets/img/img1.jpg" alt="" /></div>
      <div className="msg-body">
        <div className="row gx-3 row-cols-auto">
          <div className="col">
            <div className="msg-bubble">
              {message}
              <span>{timestamp}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function MessageRight(props) {
  const message = props.message ? props.message : 'no message';
  const timestamp = props.timestamp ? props.timestamp : '';

  return (
    <div className="msg-item reverse">
      <div className="msg-body">
        <div className="row gx-3 row-cols-auto">
          <div className="col">
            <div className="msg-bubble">
              {message}
              <span>{timestamp}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
