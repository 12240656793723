/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SdDialog from '../../components/Dialog/Dialog';
import { NotificationContext, SecurityContext } from '../../Context';
import './CompanySettings.scss';
import Api from '../../api/api';

const timezones = [
  { label: 'Afghanistan', value: 'Asia/Kabul' },
  { label: 'Albania', value: 'Europe/Tirane' },
  { label: 'Algeria', value: 'Africa/Algiers' },
  { label: 'Andorra', value: 'Europe/Andorra' },
  { label: 'Angola', value: 'Africa/Luanda' },
  { label: 'Antarctica', value: 'Antarctica/Casey' },
  { label: 'Argentina', value: 'America/Argentina/Buenos_Aires' },
  { label: 'Armenia', value: 'Asia/Yerevan' },
  { label: 'Australia', value: 'Australia/Sydney' },
  { label: 'Austria', value: 'Europe/Vienna' },
  { label: 'Azerbaijan', value: 'Asia/Baku' },
  { label: 'Bahamas', value: 'America/Nassau' },
  { label: 'Bahrain', value: 'Asia/Bahrain' },
  { label: 'Bangladesh', value: 'Asia/Dhaka' },
  { label: 'Barbados', value: 'America/Barbados' },
  { label: 'Belarus', value: 'Europe/Minsk' },
  { label: 'Belgium', value: 'Europe/Brussels' },
  { label: 'Belize', value: 'America/Belize' },
  { label: 'Benin', value: 'Africa/Porto-Novo' },
  { label: 'Bhutan', value: 'Asia/Thimphu' },
  { label: 'Bolivia', value: 'America/La_Paz' },
  { label: 'Bosnia and Herzegovina', value: 'Europe/Sarajevo' },
  { label: 'Botswana', value: 'Africa/Gaborone' },
  { label: 'Brazil', value: 'America/Sao_Paulo' },
  { label: 'Brunei', value: 'Asia/Brunei' },
  { label: 'Bulgaria', value: 'Europe/Sofia' },
  { label: 'Burkina Faso', value: 'Africa/Ouagadougou' },
  { label: 'Burundi', value: 'Africa/Bujumbura' },
  { label: 'Cambodia', value: 'Asia/Phnom_Penh' },
  { label: 'Cameroon', value: 'Africa/Douala' },
  { label: 'Canada', value: 'America/Toronto' },
  { label: 'Cape Verde', value: 'Atlantic/Cape_Verde' },
  { label: 'Central African Republic', value: 'Africa/Bangui' },
  { label: 'Chad', value: 'Africa/Ndjamena' },
  { label: 'Chile', value: 'America/Santiago' },
  { label: 'China', value: 'Asia/Shanghai' },
  { label: 'Colombia', value: 'America/Bogota' },
  { label: 'Comoros', value: 'Indian/Comoro' },
  { label: 'Congo', value: 'Africa/Brazzaville' },
  { label: 'Costa Rica', value: 'America/Costa_Rica' },
  { label: 'Croatia', value: 'Europe/Zagreb' },
  { label: 'Cuba', value: 'America/Havana' },
  { label: 'Cyprus', value: 'Asia/Nicosia' },
  { label: 'Czech Republic', value: 'Europe/Prague' },
  { label: 'Denmark', value: 'Europe/Copenhagen' },
  { label: 'Djibouti', value: 'Africa/Djibouti' },
  { label: 'Dominica', value: 'America/Dominica' },
  { label: 'Dominican Republic', value: 'America/Santo_Domingo' },
  { label: 'Ecuador', value: 'America/Guayaquil' },
  { label: 'Egypt', value: 'Africa/Cairo' },
  { label: 'El Salvador', value: 'America/El_Salvador' },
  { label: 'Equatorial Guinea', value: 'Africa/Malabo' },
  { label: 'Eritrea', value: 'Africa/Asmara' },
  { label: 'Estonia', value: 'Europe/Tallinn' },
  { label: 'Eswatini', value: 'Africa/Mbabane' },
  { label: 'Ethiopia', value: 'Africa/Addis_Ababa' },
  { label: 'Fiji', value: 'Pacific/Fiji' },
  { label: 'Finland', value: 'Europe/Helsinki' },
  { label: 'France', value: 'Europe/Paris' },
  { label: 'Gabon', value: 'Africa/Libreville' },
  { label: 'Gambia', value: 'Africa/Banjul' },
  { label: 'Georgia', value: 'Asia/Tbilisi' },
  { label: 'Germany', value: 'Europe/Berlin' },
  { label: 'Ghana', value: 'Africa/Accra' },
  { label: 'Greece', value: 'Europe/Athens' },
  { label: 'Grenada', value: 'America/Grenada' },
  { label: 'Guatemala', value: 'America/Guatemala' },
  { label: 'Guinea', value: 'Africa/Conakry' },
  { label: 'Guinea-Bissau', value: 'Africa/Bissau' },
  { label: 'Guyana', value: 'America/Guyana' },
  { label: 'Haiti', value: 'America/Port-au-Prince' },
  { label: 'Honduras', value: 'America/Tegucigalpa' },
  { label: 'Hungary', value: 'Europe/Budapest' },
  { label: 'Iceland', value: 'Atlantic/Reykjavik' },
  { label: 'India', value: 'Asia/Kolkata' },
  { label: 'Indonesia', value: 'Asia/Jakarta' },
  { label: 'Iran', value: 'Asia/Tehran' },
  { label: 'Iraq', value: 'Asia/Baghdad' },
  { label: 'Ireland', value: 'Europe/Dublin' },
  { label: 'Israel', value: 'Asia/Jerusalem' },
  { label: 'Italy', value: 'Europe/Rome' },
  { label: 'Jamaica', value: 'America/Jamaica' },
  { label: 'Japan', value: 'Asia/Tokyo' },
  { label: 'Jordan', value: 'Asia/Amman' },
  { label: 'Kazakhstan', value: 'Asia/Almaty' },
  { label: 'Kenya', value: 'Africa/Nairobi' },
  { label: 'Kiribati', value: 'Pacific/Tarawa' },
  { label: 'North Korea', value: 'Asia/Pyongyang' },
  { label: 'South Korea', value: 'Asia/Seoul' },
  { label: 'Kuwait', value: 'Asia/Kuwait' },
  { label: 'Kyrgyzstan', value: 'Asia/Bishkek' },
  { label: 'Laos', value: 'Asia/Vientiane' },
  { label: 'Latvia', value: 'Europe/Riga' },
  { label: 'Lebanon', value: 'Asia/Beirut' },
  { label: 'Lesotho', value: 'Africa/Maseru' },
  { label: 'Liberia', value: 'Africa/Monrovia' },
  { label: 'Libya', value: 'Africa/Tripoli' },
  { label: 'Liechtenstein', value: 'Europe/Vaduz' },
  { label: 'Lithuania', value: 'Europe/Vilnius' },
  { label: 'Luxembourg', value: 'Europe/Luxembourg' },
  { label: 'Madagascar', value: 'Indian/Antananarivo' },
  { label: 'Malawi', value: 'Africa/Blantyre' },
  { label: 'Malaysia', value: 'Asia/Kuala_Lumpur' },
  { label: 'Maldives', value: 'Indian/Maldives' },
  { label: 'Mali', value: 'Africa/Bamako' },
  { label: 'Malta', value: 'Europe/Malta' },
  { label: 'Marshall Islands', value: 'Pacific/Majuro' },
  { label: 'Mauritania', value: 'Africa/Nouakchott' },
  { label: 'Mauritius', value: 'Indian/Mauritius' },
  { label: 'Mexico', value: 'America/Mexico_City' },
  { label: 'Micronesia', value: 'Pacific/Pohnpei' },
  { label: 'Moldova', value: 'Europe/Chisinau' },
  { label: 'Monaco', value: 'Europe/Monaco' },
  { label: 'Mongolia', value: 'Asia/Ulaanbaatar' },
  { label: 'Montenegro', value: 'Europe/Podgorica' },
  { label: 'Morocco', value: 'Africa/Casablanca' },
  { label: 'Mozambique', value: 'Africa/Maputo' },
  { label: 'Myanmar', value: 'Asia/Yangon' },
  { label: 'Namibia', value: 'Africa/Windhoek' },
  { label: 'Nauru', value: 'Pacific/Nauru' },
  { label: 'Nepal', value: 'Asia/Kathmandu' },
  { label: 'Netherlands', value: 'Europe/Amsterdam' },
  { label: 'New Zealand', value: 'Pacific/Auckland' },
  { label: 'Nicaragua', value: 'America/Managua' },
  { label: 'Niger', value: 'Africa/Niamey' },
  { label: 'Nigeria', value: 'Africa/Lagos' },
  { label: 'Norway', value: 'Europe/Oslo' },
  { label: 'Oman', value: 'Asia/Muscat' },
  { label: 'Pakistan', value: 'Asia/Karachi' },
  { label: 'Palau', value: 'Pacific/Palau' },
  { label: 'Palestine', value: 'Asia/Gaza' },
  { label: 'Panama', value: 'America/Panama' },
  { label: 'Papua New Guinea', value: 'Pacific/Port_Moresby' },
  { label: 'Paraguay', value: 'America/Asuncion' },
  { label: 'Peru', value: 'America/Lima' },
  { label: 'Philippines', value: 'Asia/Manila' },
  { label: 'Poland', value: 'Europe/Warsaw' },
  { label: 'Portugal', value: 'Europe/Lisbon' },
  { label: 'Qatar', value: 'Asia/Qatar' },
  { label: 'Romania', value: 'Europe/Bucharest' },
  { label: 'Russia', value: 'Europe/Moscow' },
  { label: 'Rwanda', value: 'Africa/Kigali' },
  { label: 'Saint Kitts and Nevis', value: 'America/St_Kitts' },
  { label: 'Saint Lucia', value: 'America/St_Lucia' },
  { label: 'Saint Vincent and the Grenadines', value: 'America/St_Vincent' },
  { label: 'Samoa', value: 'Pacific/Apia' },
  { label: 'San Marino', value: 'Europe/San_Marino' },
  { label: 'Sao Tome and Principe', value: 'Africa/Sao_Tome' },
  { label: 'Saudi Arabia', value: 'Asia/Riyadh' },
  { label: 'Senegal', value: 'Africa/Dakar' },
  { label: 'Serbia', value: 'Europe/Belgrade' },
  { label: 'Seychelles', value: 'Indian/Mahe' },
  { label: 'Sierra Leone', value: 'Africa/Freetown' },
  { label: 'Singapore', value: 'Asia/Singapore' },
  { label: 'Slovakia', value: 'Europe/Bratislava' },
  { label: 'Slovenia', value: 'Europe/Ljubljana' },
  { label: 'Solomon Islands', value: 'Pacific/Guadalcanal' },
  { label: 'Somalia', value: 'Africa/Mogadishu' },
  { label: 'South Africa', value: 'Africa/Johannesburg' },
  { label: 'South Sudan', value: 'Africa/Juba' },
  { label: 'Spain', value: 'Europe/Madrid' },
  { label: 'Sri Lanka', value: 'Asia/Colombo' },
  { label: 'Sudan', value: 'Africa/Khartoum' },
  { label: 'Suriname', value: 'America/Paramaribo' },
  { label: 'Sweden', value: 'Europe/Stockholm' },
  { label: 'Switzerland', value: 'Europe/Zurich' },
  { label: 'Syria', value: 'Asia/Damascus' },
  { label: 'Taiwan', value: 'Asia/Taipei' },
  { label: 'Tajikistan', value: 'Asia/Dushanbe' },
  { label: 'Tanzania', value: 'Africa/Dar_es_Salaam' },
  { label: 'Thailand', value: 'Asia/Bangkok' },
  { label: 'Timor-Leste', value: 'Asia/Dili' },
  { label: 'Togo', value: 'Africa/Lome' },
  { label: 'Tonga', value: 'Pacific/Tongatapu' },
  { label: 'Trinidad and Tobago', value: 'America/Port_of_Spain' },
  { label: 'Tunisia', value: 'Africa/Tunis' },
  { label: 'Turkey', value: 'Europe/Istanbul' },
  { label: 'Turkmenistan', value: 'Asia/Ashgabat' },
  { label: 'Tuvalu', value: 'Pacific/Funafuti' },
  { label: 'Uganda', value: 'Africa/Kampala' },
  { label: 'Ukraine', value: 'Europe/Kiev' },
  { label: 'United Arab Emirates', value: 'Asia/Dubai' },
  { label: 'United Kingdom', value: 'Europe/London' },
  { label: 'United States', value: 'America/New_York' },
  { label: 'Uruguay', value: 'America/Montevideo' },
  { label: 'Uzbekistan', value: 'Asia/Tashkent' },
  { label: 'Vanuatu', value: 'Pacific/Efate' },
  { label: 'Vatican City', value: 'Europe/Vatican' },
  { label: 'Venezuela', value: 'America/Caracas' },
  { label: 'Vietnam', value: 'Asia/Ho_Chi_Minh' },
  { label: 'Yemen', value: 'Asia/Aden' },
  { label: 'Zambia', value: 'Africa/Lusaka' },
  { label: 'Zimbabwe', value: 'Africa/Harare' },
];

const columns = [
  { field: '_id', headerName: 'Id', width: 70 },
  { field: 'name', headerName: 'Group Name', width: 130 },
  { field: 'email', headerName: 'Email', width: 130 },
  { field: 'phone', headerName: 'Phone', width: 130 },
  {
    field: 'isEnabled', headerName: 'Is Active', width: 130, valueGetter: ({ row }) => `${row.isEnabled ? 'Yes' : 'No'}`,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 130,
    valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY hh:mm A'),
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 130,
    valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY hh:mm A'),
  },
];

function CustomTabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Companies() {
  const { t } = useTranslation();
  const { permissions, setPermissions } = useContext(SecurityContext);

  const { notification, setNotification } = useContext(NotificationContext);

  const [dialogData, setDialogData] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [settings, setSettings] = useState();
  const [value, setValue] = React.useState(0);
  const [currencies, setCurrencies] = useState(['USD', 'SGD', 'EUR']);
  const [availableCurrency, setAvailableCurrency] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getCompanySttings = () => {
    Api.getCompaniesSettings().then((res) => {
      setSettings({ ...res.data?.company, ...res.data?.settings });
      setAvailableCurrency(res.data.company.accounts ? _.difference(currencies, Object.keys(res.data.company.accounts)) : currencies);
    });
  };

  useEffect(() => {
    getCompanySttings();
  }, []);

  const updateField = (e, field, v, currency) => {
    if (currency) {
      setSettings({
        ...settings,
        accounts: {
          ...settings.accounts,
          [currency]: {
            ...settings.accounts[currency],
            [field]: (v && v !== null) ? v.value : e.target.value,
          },
        },
      });
    } else {
      setSettings({
        ...settings,
        [field]: v ? v.value : e.target.value,
      });
    }
  };

  const handleSubmit = () => {
    Api.setCompaniesSettings(settings).then((res) => {
      setNotification({
        ...notification,
        open: true,
        message: 'The settings were successfully changed!',
      });
      getCompanySttings();
    })
      .catch((err) => {
        setNotification({
          ...notification,
          open: true,
          message: 'There was an error saving the settings.',
        });
      });
  };

  const addAccount = (account) => {
    console.log(' Add account');
    setSettings({
      ...settings,
      accounts: {
        ...settings.accounts,
        [selectedCurrency]: {
          bankAccount: null,
          bankBIC: null,
          bankCompanyName: null,
          bankCurrency: selectedCurrency,
          bankName: null,
          bankSWIFT: null,
        },
      },
    });
    setSelectedCurrency();
  };

  const removeAccount = (account) => {
    const newAccounts = _.omit(settings.accounts, account);
    setSettings({ ...settings, accounts: newAccounts });
  };

  return (
    <>
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item"><a href="/#">Company Management</a></li>
            <li className="breadcrumb-item active" aria-current="page">Company Settings</li>
          </ol>
          <h4 className="main-title mb-0">Company Settings</h4>
        </div>
        <div className="d-flex gap-2 mt-3 mt-md-0">
          <button type="button" className="btn btn-primary d-flex align-items-center gap-2" onClick={handleSubmit}>
            <i className="ri-save-fill fs-18 lh-1" />
            <span className="d-none d-sm-inline"> Save Changes</span>
          </button>
        </div>
      </div>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="General" {...a11yProps(0)} />
          <Tab label="Operational" {...a11yProps(1)} />
          <Tab label="Banking" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <div className="card card-settings">
          <div className="card-header">
            <h5 className="card-title">Company Information</h5>
            <p className="card-text">Set below the contact information</p>
          </div>
          <div className="card-body p-0">

            <div className="setting-item">
              <div className="row g-2 align-items-center">
                <div className="col-md-5">
                  <h6>Company Name</h6>
                  <p>The mane of the company that will be used in communication</p>
                </div>
                <div className="col-md">
                  <TextField type="text" className="form-control" value={settings?.name} disabled />
                  <div data-lastpass-icon-root="" />
                </div>
              </div>
            </div>

            <div className="setting-item">
              <div className="row g-2 align-items-center">
                <div className="col-md-5">
                  <h6>Contact Number</h6>
                  <p>The contact phone number</p>
                </div>
                <div className="col-md">
                  <TextField type="text" className="form-control" value={settings?.phone} onChange={(e) => updateField(e, 'phone')} />
                  <div data-lastpass-icon-root="" />
                </div>
              </div>
            </div>

            <div className="setting-item">
              <div className="row g-2 align-items-center">
                <div className="col-md-5">
                  <h6>Contact email</h6>
                  <p>The contact email of the company</p>
                </div>
                <div className="col-md">
                  <TextField type="text" className="form-control" value={settings?.email} onChange={(e) => updateField(e, 'email')} />
                  <div data-lastpass-icon-root="" />
                </div>
              </div>
            </div>

          </div>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div className="card card-settings">
          <div className="card-header">
            <h5 className="card-title">Operational Information</h5>
            <p className="card-text">Config how the system will work in close relation to your needs.</p>
          </div>

          <div className="setting-item">
            <div className="row g-2 align-items-center">
              <div className="col-md-5">
                <h6>Debt Collection expiration</h6>
                <p>The maximum number of months allowed for the debt collection.</p>
              </div>
              <div className="col-md">
                <TextField type="text" className="form-control" value={settings?.maxRepaymentTerm} onChange={(e) => updateField(e, 'maxRepaymentTerm')} />
                <div data-lastpass-icon-root="" />
              </div>
            </div>
          </div>

          <div className="setting-item">
            <div className="row g-2 align-items-center">
              <div className="col-md-5">
                <h6>Initial Channel</h6>
                <p>Set how the debtors will be contacted initially </p>
              </div>
              <div className="col-md">
                <TextField type="text" className="form-control" value={settings?.startStream} disabled onChange={(e) => updateField(e, 'startStream')} />
                <div data-lastpass-icon-root="" />
              </div>
            </div>
          </div>

          <div className="setting-item">
            <div className="row g-2 align-items-center">
              <div className="col-md-5">
                <h6>Re-contact channel</h6>
                <p>Set how the debtors will be re-contacted in case of missing payment.</p>
              </div>
              <div className="col-md">
                <TextField type="text" className="form-control" value="call" disabled onChange={(e) => updateField(e, '"')} />
                <div data-lastpass-icon-root="" />
              </div>
            </div>
          </div>

          <div className="setting-item">
            <div className="row g-2 align-items-center">
              <div className="col-md-5">
                <h6>Re-contact time interval</h6>
                <p>Time to contact the user again if there is no payment registered - in days.</p>
              </div>
              <div className="col-md">
                <TextField type="text" className="form-control" value={settings?.restreamInterval} onChange={(e) => updateField(e, 'restreamInterval')} />
                <div data-lastpass-icon-root="" />
              </div>
            </div>
          </div>

          <div className="setting-item">
            <div className="row g-2 align-items-center">
              <div className="col-md-5">
                <h6>Call Again Number of Times</h6>
                <p>How many times to call the user again if call was not answered, busy or rejected</p>
              </div>
              <div className="col-md">
                <TextField type="text" className="form-control" value={settings?.callAgainTimes} onChange={(e) => updateField(e, 'callAgainTimes')} />
                <div data-lastpass-icon-root="" />
              </div>
            </div>
          </div>

          <div className="setting-item">
            <div className="row g-2 align-items-center">
              <div className="col-md-5">
                <h6>Call Again Interval</h6>
                <p>The interval in minutes between the calls in case not answering or busy</p>
              </div>
              <div className="col-md">
                <TextField type="text" className="form-control" value={settings?.callAgainInterval} onChange={(e) => updateField(e, 'callAgainInterval')} />
                <div data-lastpass-icon-root="" />
              </div>
            </div>
          </div>

          <div className="setting-item">
            <div className="row g-2 align-items-center">
              <div className="col-md-5">
                <h6>Debtors Timezone</h6>
                <p>Set the timezone that will be used to contact the debtors</p>
              </div>
              <div className="col-md">
                {/* <TextField type="text" className="form-control" value={settings?.timezone} disabled onChange={(e) => updateField(e, 'timezone')} /> */}
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={timezones.filter((item) => item.value === settings?.timezone)[0]?.label}
                  options={timezones}
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(e, v) => updateField(e, 'timezone', v)}
                />
                <div data-lastpass-icon-root="" />
              </div>
            </div>
          </div>

          <div className="setting-item">
            <div className="row g-2 align-items-center">
              <div className="col-md-5">
                <h6>Email hours</h6>
                <p>Set the time interval in which the emails will be sent</p>
              </div>
              <div className="col-md">
                <TextField type="text" className="form-control" value={settings?.emailHours} onChange={(e) => updateField(e, 'emailHours')} />
                <div data-lastpass-icon-root="" />
              </div>
            </div>
          </div>

          <div className="setting-item">
            <div className="row g-2 align-items-center">
              <div className="col-md-5">
                <h6>Call hours</h6>
                <p>Set the time interval in which the calls will be initiated</p>
              </div>
              <div className="col-md">
                <TextField
                  type="text"
                  className="form-control"
                  value={settings?.callHours}
                  onChange={(e) => updateField(e, 'callHours')}
                />
                <div data-lastpass-icon-root="" />
              </div>
            </div>
          </div>

        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <div className="card card-settings">
          <div className="card-header">
            <h5 className="card-title">Bank Information</h5>
            <p className="card-text">Bank and account details for the debt recovery</p>

            { (availableCurrency && availableCurrency?.length > 0) && (
            <nav className="nav nav-icon nav-icon-sm ms-auto" style={{ float: 'right' }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedCurrency}
                label="Age"
                onChange={(e) => setSelectedCurrency(e.target.value)}
              >
                {availableCurrency && availableCurrency.map((currency) => (
                  <MenuItem value={currency}>{currency}</MenuItem>
                ))}
              </Select>

              { selectedCurrency && (
              <a className="nav-link" title="Add new account" onClick={() => addAccount()} style={{ cursor: 'pointer' }}>
                Add new account
              </a>
              )}

            </nav>
            )}

          </div>

          {/* account iteration starts */}
          { settings?.accounts && Object.keys(settings?.accounts).map((account, index) => (

            <div style={{ background: index % 2 === 0 ? '#FFF' : '#e1e1e1' }}>
              <div className="card-header">
                <h5 className="card-title">
                  Bank Information for the
                  {' '}
                  {account}
                  {' '}
                  account
                </h5>
                <nav className="nav nav-icon nav-icon-sm ms-auto" style={{ float: 'right' }}>
                  <a className="nav-link" title="Add new account" onClick={() => removeAccount(account)} style={{ cursor: 'pointer' }}>
                    Remove the
                    {' '}
                    {account}
                    {' '}
                    account
                  </a>
                </nav>
              </div>

              <div className="setting-item">
                <div className="row g-2 align-items-center">
                  <div className="col-md-5">
                    <h6>Account Holder</h6>
                    <p>The name of the bank account holder, as registered.</p>
                  </div>
                  <div className="col-md">
                    <TextField type="text" className="form-control" value={settings.accounts[account].bankCompanyName} onChange={(e) => updateField(e, 'bankCompanyName', null, account)} />
                    <div data-lastpass-icon-root="" />
                  </div>
                </div>
              </div>

              <div className="setting-item">
                <div className="row g-2 align-items-center">
                  <div className="col-md-5">
                    <h6>Bank Name</h6>
                    <p>The name of the bank account holder, as registered.</p>
                  </div>
                  <div className="col-md">
                    <TextField type="text" className="form-control" value={settings.accounts[account].bankName} onChange={(e) => updateField(e, 'bankName', null, account)} />
                    <div data-lastpass-icon-root="" />
                  </div>
                </div>
              </div>

              <div className="setting-item">
                <div className="row g-2 align-items-center">
                  <div className="col-md-5">
                    <h6>Bank SWIFT</h6>
                    <p>The name of the bank account holder, as registered.</p>
                  </div>
                  <div className="col-md">
                    <TextField type="text" className="form-control" value={settings.accounts[account].bankSWIFT} onChange={(e) => updateField(e, 'bankSWIFT', null, account)} />
                    <div data-lastpass-icon-root="" />
                  </div>
                </div>
              </div>

              <div className="setting-item">
                <div className="row g-2 align-items-center">
                  <div className="col-md-5">
                    <h6>Bank BIC</h6>
                    <p>The name of the bank account holder, as registered.</p>
                  </div>
                  <div className="col-md">
                    <TextField type="text" className="form-control" value={settings.accounts[account].bankBIC} onChange={(e) => updateField(e, 'bankBIC', null, account)} />
                    <div data-lastpass-icon-root="" />
                  </div>
                </div>
              </div>

              <div className="setting-item">
                <div className="row g-2 align-items-center">
                  <div className="col-md-5">
                    <h6>Account Number</h6>
                    <p>The name of the bank account holder, as registered.</p>
                  </div>
                  <div className="col-md">
                    <TextField type="text" className="form-control" value={settings.accounts[account].bankAccount} onChange={(e) => updateField(e, 'bankAccount', null, account)} />
                    <div data-lastpass-icon-root="" />
                  </div>
                </div>
              </div>

              <div className="setting-item">
                <div className="row g-2 align-items-center">
                  <div className="col-md-5">
                    <h6>Account Currency</h6>
                    <p>The currency of the  bank account, as registered.</p>
                  </div>
                  <div className="col-md">
                    <TextField type="text" className="form-control" value={settings.accounts[account].bankCurrency} disabled />
                    <div data-lastpass-icon-root="" />
                  </div>
                </div>
              </div>

              {/* <div className="setting-item">
                <div className="row g-2 align-items-center">
                  <div className="col-md-5">
                    <h6>Account Currency</h6>
                    <p>The name of the bank account holder, as registered.</p>
                      <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={settings.accounts[account].bankCurrency}
                      label="Age"
                      onChange={(e) => updateField(e, 'bankCurrency', null, account)}
                    >
                      <MenuItem value="USD">USD</MenuItem>
                      <MenuItem value="EUR">EUR</MenuItem>
                      <MenuItem value="SGD">SGD</MenuItem>
                    </Select>
                  </div>
                  <div className="col-md">

                    <div data-lastpass-icon-root="" />
                  </div>
                </div>
              </div> */}
            </div>
          ))}
          {/* account iteration ends */}

        </div>
      </CustomTabPanel>

      <SdDialog
        data={dialogData}
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
      />

    </>
  );
}

export default Companies;
