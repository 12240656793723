import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';

const useStyles = makeStyles(() => createStyles({
  wrapForm: {
    display: 'flex',
    justifyContent: 'center',
    width: '95%',
    margin: '0',
  },
  wrapText: {
    width: '100%',
  },
  button: {
    // margin: theme.spacing(1),
  },
}));

function TextInput({ setChatEntry, disabled }) {
  const classes = useStyles();
  const [message, setMessage] = useState('');

  const sendMessage = () => {
    setChatEntry(message);
    setMessage('');
  };

  const checkSend = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <form className={classes.wrapForm} noValidate autoComplete="off">
      <TextField
        id="standard-text"
        label=""
        className={classes.wrapText}
        onChange={(e) => setMessage(e.target.value)}
        value={message}
        onKeyDown={(e) => checkSend(e)}
        disabled={disabled}
      />
      <Button variant="contained" color="primary" className={classes.button} onClick={sendMessage} disabled={disabled}>
        <SendIcon />
      </Button>
    </form>
  );
}

export default TextInput;
