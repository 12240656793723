/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import './Dashboard.scss';
import Api from '../../api/api';

function Dashboard() {
  const [data, setData] = useState({});

  const getDashboardData = () => {
    Api.getDashboard().then((res) => {
      setData(res.data.data);
      console.log(' xxxx xDASH DATA', res.data.data);
    });
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <>
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item"><a href="/#">Dashboard</a></li>
            <li className="breadcrumb-item active" aria-current="page">Finance Monitoring</li>
          </ol>
          <h4 className="main-title mb-0">Welcome to Dashboard</h4>
        </div>
        <div className="d-flex gap-2 mt-3 mt-md-0">
          {/* <button type="button" className="btn btn-white d-flex align-items-center gap-2">
            <i className="ri-share-line fs-18 lh-1" />
            Share
          </button>
          <button type="button" className="btn btn-white d-flex align-items-center gap-2">
            <i className="ri-printer-line fs-18 lh-1" />
            Print
          </button>
          <button type="button" className="btn btn-primary d-flex align-items-center gap-2">
            <i className="ri-bar-chart-2-line fs-18 lh-1" />
            Generate
            <span className="d-none d-sm-inline"> Report</span>
          </button> */}
        </div>
      </div>
      <div className="row g-3">

        <div className="col-6 col-xl-3">
          <div className="card card-one">
            <div className="card-body">
              <label className="card-title fs-sm fw-medium mb-1">Average collection time</label>
              <h3 className="card-value mb-1">
                <i className="ri-shopping-bag-3-line" />
                {data?.debt?.avgTime || 0}
                {' '}
                day(s)

              </h3>

            </div>
          </div>
        </div>
        <div className="col-6 col-xl-3">
          <div className="card card-one">
            <div className="card-body">
              <label className="card-title fs-sm fw-medium mb-1">Best collection time</label>
              <h3 className="card-value mb-1">
                <i className="ri-shopping-bag-3-line" />
                {data?.debt?.minTime || 0}
                {' '}
                {' '}
                day(s)
              </h3>
            </div>
          </div>
        </div>
        <div className="col-6 col-xl-3">
          <div className="card card-one">
            <div className="card-body">
              <label className="card-title fs-sm fw-medium mb-1">Completed cases</label>
              <h3 className="card-value mb-1">
                <i className="ri-shopping-bag-3-line" />
                {' '}
                {data?.debt?.recovered || 0}
                {' '}
                {data?.company?.currency}
              </h3>
              <small>
                {data?.cases?.completed || 0}
                {' '}
                cases
              </small>
            </div>
          </div>
        </div>
        <div className="col-6 col-xl-3">
          <div className="card card-one">
            <div className="card-body">
              <label className="card-title fs-sm fw-medium mb-1">Collection Rate</label>
              <h3 className="card-value mb-1">
                <i className="ri-bar-chart-box-line" />
                {' '}
                {data?.debt?.rate || 0}
                <span>%</span>
              </h3>
            </div>
          </div>
        </div>
        {/* row 2 */}
        <div className="col-6 col-xl-6">
          <div className="card card-one">
            <div className="card-body">
              <label className="card-title fs-sm fw-medium mb-1">Total Cases</label>
              <h3 className="card-value mb-1">
                <i className="ri-shopping-bag-3-line" />
                {' '}
                {data?.cases?.total?.amount || 0}
                {' '}
                {data?.company?.currency}
              </h3>
              <small>
                {data?.cases?.total?.cases || 0}
                {' '}
                cases
              </small>
            </div>
          </div>
        </div>
        <div className="col-6 col-xl-6">
          <div className="card card-one">
            <div className="card-body">
              <label className="card-title fs-sm fw-medium mb-1">Active Cases</label>
              <h3 className="card-value mb-1">
                <i className="ri-shopping-bag-3-line" />
                {' '}
                {data?.cases?.active?.amount || 0}
                {' '}
                {data?.company?.currency}
              </h3>
              <small>
                {data?.cases?.active?.cases || 0}
                {' '}
                cases
              </small>
            </div>
          </div>
        </div>

        {/* end row 2 */}
        <div className="col-md-6 col-xl-4">
          <div className="card card-one">
            <div className="card-header">
              <h6 className="card-title">Real Time Sales</h6>
              <nav className="nav nav-icon nav-icon-sm ms-auto">
                <a href="" className="nav-link"><i className="ri-refresh-line" /></a>
                <a href="" className="nav-link"><i className="ri-more-2-fill" /></a>
              </nav>
            </div>
            <div className="card-body">
              <div className="chart-bar-one" />
            </div>
          </div>
        </div>

        <div className="col-md-6 col-xl-4">
          <div className="card card-one">
            <div className="card-header">
              <h6 className="card-title">Recently Active</h6>
              <nav className="nav nav-icon nav-icon-sm ms-auto">
                <a href="" className="nav-link"><i className="ri-refresh-line" /></a>
                <a href="" className="nav-link"><i className="ri-more-2-fill" /></a>
              </nav>
            </div>
            <div className="card-body p-0">
              {/* <ul className="people-group">
                <li className="people-item">
                  <div className="avatar"><img src="../assets/img/img10.jpg" alt="" /></div>
                  <div className="people-body">
                    <h6><a href="">Rolando Paloso</a></h6>
                    <span>Customer ID#00218</span>
                  </div>
                  <nav className="nav nav-icon">
                    <a href="" className="nav-link"><i className="ri-user-star-line" /></a>
                    <a href="" className="nav-link"><i className="ri-contacts-line" /></a>
                  </nav>
                </li>
              </ul> */}
            </div>
            {/* <div className="card-footer d-flex justify-content-center">
              <a href="" className="fs-sm">Manage Customers</a>
            </div> */}
          </div>
        </div>

        <div className="col-md-6 col-xl-4">
          <div className="card card-one">
            <div className="card-header">
              <h6 className="card-title">Transaction History</h6>
              <nav className="nav nav-icon nav-icon-sm ms-auto">
                <a href="" className="nav-link"><i className="ri-refresh-line" /></a>
                <a href="" className="nav-link"><i className="ri-more-2-fill" /></a>
              </nav>
            </div>
            <div className="card-body p-0">
              {/* <ul className="people-group">
                <li className="people-item">
                  <div className="avatar"><span className="avatar-initial bg-secondary fs-20"><i className="ri-secure-payment-line" /></span></div>
                  <div className="people-body">
                    <h6><a href="">Payment failed #087651</a></h6>
                    <span>Oct 15, 2023, 08:09am</span>
                  </div>
                  <div className="text-end">
                    <div className="fs-sm">$150.20</div>
                    <span className="d-block fs-xs text-danger">Declined</span>
                  </div>
                </li>
              </ul> */}
            </div>
            {/* <div className="card-footer d-flex justify-content-center">
              <a href="" className="fs-sm">Manage Transactions</a>
            </div> */}
          </div>
        </div>

      </div>
    </>
  );
}

export default Dashboard;
