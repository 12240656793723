/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useContext, useState } from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import { NotificationContext, SecurityContext } from '../../Context';
import checkPermission from '../../helpers/helpers';
import './Hooks.scss';
import Api from '../../api/api';
import SdDialog from '../../components/Dialog/Dialog';

const columns = [
  { field: '_id', headerName: 'Id', width: 70 },
  {
    field: 'name', headerName: 'First Name', width: 130,
  },
  {
    field: 'comment', headerName: 'Details', width: 130,
  },
  {
    field: 'expression', headerName: 'Trigger Phrase(s)', width: 130,
  },
  {
    field: 'function', headerName: 'Backend Function', width: 130,
  },
  {
    field: 'isEnabled', headerName: 'Is Active', width: 130, valueGetter: ({ row }) => `${row.isEnabled ? 'Yes' : 'No'}`,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 130,
    valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY hh:mm A'),
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 130,
    valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY hh:mm A'),
  },
];

function Hooks() {
  const { permissions, setPermissions } = useContext(SecurityContext);
  const [hooks, setHooks] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filtereddRows, setFilteredRows] = useState([]);
  const [groups, setGroups] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [dialogData, setDialogData] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { notification, setNotification } = useContext(NotificationContext);

  const getHooks = () => {
    Api.getHooks().then((res) => {
      setHooks(res.data.body);
    });

    Api.getCompanies().then((res) => {
      setCompanies(res.data.body);
    });
  };

  useEffect(() => {
    getHooks();
  }, []);

  const getEditValue = (id, field) => {
    console.log(' xxxx ', id, field, hooks);
    const entry = hooks.find((data) => data._id === id);
    switch (field) {
      default:
        return entry[field];
    }
  };

  const updateEntry = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries((formData).entries());
    Api.updateHooks(data).then((res) => {
      setNotification({
        ...notification,
        open: true,
        message: 'Hook successfully updated!',
      });
      getHooks();
    })
      .catch((err) => {
        setNotification({
          ...notification,
          open: true,
          message: err,
        });
      });
    setIsDialogOpen(false);
    getHooks();
  };

  const handleDialogOpen = (action) => {
    setIsDialogOpen(true);
    switch (action) {
      case 'edit':
        setDialogData({
          type: 'form',
          title: 'Update hook',
          description: '',
          fields: [
            {
              type: 'hidden',
              id: 'hookId',
              label: 'Hook Id',
              defaultValue: selectedRows[0],
            },
            {
              type: 'text',
              id: 'name',
              label: 'Name',
              defaultValue: getEditValue(selectedRows[0], 'name'),
            },
            {
              type: 'text',
              id: 'comment',
              label: 'Description',
              defaultValue: getEditValue(selectedRows[0], 'comment'),
            },
            {
              type: 'text',
              id: 'expression',
              label: 'Trigger Expression',
              defaultValue: getEditValue(selectedRows[0], 'expression'),
            },
            {
              type: 'text',
              id: 'function',
              label: 'Backend Function',
              defaultValue: getEditValue(selectedRows[0], 'function'),
            },
            {
              type: 'check',
              id: 'isEnabled',
              label: 'Is Enabled',
              defaultValue: getEditValue(selectedRows[0], 'isEnabled'),
            },
          ],
          buttons: {
            ok: {
              title: 'Save',
              action: updateEntry,
            },
          },
        });
        setIsDialogOpen(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item"><a href="/#">Configuration</a></li>
            <li className="breadcrumb-item active" aria-current="page">Hooks Management</li>
          </ol>
          <h4 className="main-title mb-0">Hooks Management</h4>
        </div>
        <div className="d-flex gap-2 mt-3 mt-md-0">
          {/* <Button variant="" onClick={() => handleDialogOpen('add')} disabled={!checkPermission(permissions, 'case', 'write')}>
            <Tooltip title="Add new case">
              <AddIcon />
            </Tooltip>
          </Button> */}
          <Button variant="" disabled={selectedRows?.length !== 1 || !checkPermission(permissions, 'billing', 'write')} onClick={() => handleDialogOpen('edit')}>
            <Tooltip title="Edit case">
              <EditIcon />
            </Tooltip>
          </Button>
          {/* <Button variant="" disabled={selectedRows?.length === 0 || !checkPermission(permissions, 'case', 'delete')} onClick={() => handleDialogOpen('delete')}>
            <Tooltip title="Delete case(s)">
              <DeleteIcon />
            </Tooltip>
          </Button> */}
        </div>
      </div>

      <div className="col-12">
        <div className="card card-one logged">
          <DataGrid
            getRowId={(row) => row._id}
            rows={hooks}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            onRowSelectionModelChange={(rows, details) => {
              setSelectedRows(rows);
            }}
          />
        </div>
      </div>

      <SdDialog
        data={dialogData}
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
      />

    </>
  );
}

export default Hooks;
