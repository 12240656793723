/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useRef } from 'react';
import './DemoChat.scss';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Paper,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { ConstructionOutlined, LinkOff, Newspaper } from '@mui/icons-material';
import chatModel from '../../helpers/templates/chat_demo';
import Api from '../../api/api';
import TextInput from './TextInput';
import { MessageLeft, MessageRight } from './Message';

const useStyles = makeStyles((theme) => createStyles({
  paper: {
    width: '80vw',
    height: '80vh',
    maxWidth: '500px',
    maxHeight: '700px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
  },
  paper2: {
    width: '80vw',
    maxWidth: '500px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
  },
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  messagesBody: {
    width: 'calc( 100% - 20px )',
    margin: 10,
    overflowY: 'scroll',
    height: 'calc( 100% - 80px )',
  },
}));

function PersonalAssistant() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { linkId } = useParams();
  const [searchParams] = useSearchParams();

  const classes = useStyles();
  const chatbox = useRef(null);
  const [message, setMessage] = useState();
  const [messageStack, setMessageStack] = useState([]);
  const [userData, setUserData] = useState();
  const [caseId, setCaseId] = useState();
  const [instruction, setInstruction] = useState();
  const [isLoading, setIsLoading] = useState();
  const [isTyping, setIsTyping] = useState();

  const getChatModel = () => [
    {
      role: 'system',
      content: chatModel({
        firstName: searchParams.get('firstName'),
        lastName: searchParams.get('lastName'),
        debtorCompanyName: searchParams.get('debtorCompanyName'),
        creditorCompanyName: searchParams.get('creditorCompanyName'),
        invoiceDate: moment().format('MMMM Do YYYY'),
        amount: searchParams.get('amount'),
        currency: searchParams.get('currency'),
        repayment: searchParams.get('repayment'),
        email: searchParams.get('email'),
        paymentLink: 'https://pay.sansdebt.com/a131tsdt2',
      }),
    },
    {
      role: 'user',
      content: 'hi',
    },
  ];

  const scrollIntoView = () => {
    // Get the div element
    const divElement = document.getElementById('style-1');
    // Scroll to the bottom of the div
    divElement.scrollTop = divElement.scrollHeight + 100;
  };

  useEffect(() => {
    setIsLoading(true);
    // get the user and case data
    Api.getLink(linkId).then((res) => {
      // if (res.data.body.length === 0) {
      //   navigate('/');
      // }
      setCaseId(res.data.body[0].case);
      Api.getCase(res.data.body[0].case).then((resp) => {
        setUserData(resp.data.body[0]);
      });
    });
  }, []);

  useEffect(() => {
    const instr = getChatModel(userData);
    setInstruction(instr);
    // init conversation
    Api.postAiMessage({ messages: getChatModel(userData) }).then((res) => {
      setMessageStack([{
        ...res.data,
        timestamp: moment().format('MMMM Do YYYY, h:mm:ss a'),
        displayName: process.env.REACT_APP_AGENT_NAME,
      }]);
      setIsLoading(false);
    });
  }, [userData]);

  useEffect(() => {
    if (typeof message !== 'undefined') {
      setIsLoading(true);
      setMessageStack([...messageStack, {
        role: 'user',
        content: message,
        timestamp: moment().format('MMMM Do YYYY, h:mm:ss a'),
        displayName: `${userData?.firstName} ${userData?.lastName}`,
      }]);
    }
  }, [message]);

  useEffect(() => {
    if (messageStack.length > 0 && messageStack[messageStack.length - 1].role === 'user') {
      Api.postDemoMessage({
        messages: instruction ? [...instruction, ...messageStack] : messageStack,
      }).then((res) => {
        setIsLoading(false);
        setMessageStack([...messageStack, { ...res.data, timestamp: moment().format('MMMM Do YYYY, h:mm:ss a'), displayName: process.env.REACT_APP_AGENT_NAME }]);
      });
    }
    scrollIntoView();
    if (messageStack[messageStack.length - 1]?.role !== 'assistant') {
      setIsTyping(true);
    } else {
      setIsTyping(false);
    }
  }, [messageStack]);

  return (
    <div className={classes.container}>
      <Paper className={classes.paper} zDepth={2}>
        <Paper id="style-1" className={classes.messagesBody} ref={chatbox}>
          {messageStack ? messageStack.map((item) => {
            if (item.role === 'assistant') {
              return (
                <MessageLeft
                  message={item.content}
                  timestamp={item.timestamp}
                  photoURL={item.photo}
                  displayName={item.displayName}
                  avatarDisp={item.avatarDisp}
                />
              );
            }
            if (item.role === 'user') {
              return (
                <MessageRight
                  message={item.content}
                  timestamp={item.timestamp}
                  photoURL={item.photo}
                  displayName={searchParams.get('firstName')}
                  avatarDisp={item.avatarDisp}
                />
              );
            }
            return true;
          }) : ''}
          { isTyping && (
          <div className="typing">
            {process.env.REACT_APP_AGENT_NAME}
            {' '}
            is typing...
          </div>
          )}
        </Paper>
        <TextInput setChatEntry={setMessage} disabled={isLoading || userData?.isCompleted} />
      </Paper>
    </div>
  );
}

export default PersonalAssistant;
