/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useContext, useState } from 'react';
import moment from 'moment';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { NotificationContext } from '../../Context';
import './Groups.scss';
import Api from '../../api/api';

const columns = [
  { field: '_id', headerName: 'Id', width: 70 },
  { field: 'name', headerName: 'Group Name', width: 130 },
  {
    field: 'company', headerName: 'Company', width: 130, valueGetter: (company) => company?.value?.name,
  },
  {
    field: 'isEnabled', headerName: 'Is Active', width: 130, valueGetter: ({ row }) => `${row.isEnabled ? 'Yes' : 'No'}`,
  },
  {
    field: 'isDeleted', headerName: 'Is Deleted', width: 130, valueGetter: ({ row }) => `${row.isDeleted ? 'Yes' : 'No'}`,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 130,
    valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY hh:mm A'),
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 130,
    valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY hh:mm A'),
  },
];

function Groups() {
  const [groups, setGroups] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filtereddRows, setFilteredRows] = useState([]);
  const { notification, setNotification } = useContext(NotificationContext);

  useEffect(() => {
    Api.getGroups().then((res) => {
      setGroups(res.data.body);
    });
    setNotification({
      ...notification,
      open: true,
      message: 'Toaster message',
    });
  }, []);

  return (
    <>
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item"><a href="/#">Groups Management</a></li>
            <li className="breadcrumb-item active" aria-current="page">Groups</li>
          </ol>
          <h4 className="main-title mb-0">Groups Management</h4>
        </div>
        {/* <div className="d-flex gap-2 mt-3 mt-md-0">
          <Button variant="">
            <Tooltip title="Add new user">
              <AddIcon />
            </Tooltip>
          </Button>
          <Button variant="" disabled={selectedRows?.length !== 1}>
            <Tooltip title="Edit user">
              <EditIcon />
            </Tooltip>
          </Button>
          <Button variant="" disabled={selectedRows?.length === 0}>
            <Tooltip title="Delete user(s)">
              <DeleteIcon />
            </Tooltip>
          </Button>
        </div> */}
      </div>

      <div className="col-12">
        <div className="card card-one logged">
          <DataGrid
            getRowId={(row) => row._id}
            rows={groups}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            onRowSelectionModelChange={(rows, details) => {
              setSelectedRows(rows);
            }}
          />
        </div>
      </div>
    </>
  );
}

export default Groups;
