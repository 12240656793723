/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useRef } from 'react';
import './PersonalAssistant.scss';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Paper,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import {
  ConstructionOutlined, Diversity1, LinkOff, Newspaper,
} from '@mui/icons-material';
import chatModel from '../../helpers/templates/chat';
import Api from '../../api/api';
import TextInput from './TextInput';
import { MessageLeft, MessageRight } from './Message';

const useStyles = makeStyles((theme) => createStyles({
  paper: {
    width: '80vw',
    height: '80vh',
    maxWidth: '500px',
    maxHeight: '700px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
  },
  paper2: {
    width: '80vw',
    maxWidth: '500px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
  },
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  messagesBody: {
    width: 'calc( 100% - 20px )',
    margin: 10,
    overflowY: 'scroll',
    height: 'calc( 100% - 80px )',
  },
}));

function PersonalAssistant() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { linkId } = useParams();

  const classes = useStyles();
  const chatbox = useRef(null);
  const [message, setMessage] = useState();
  const [messageStack, setMessageStack] = useState([]);
  const [userData, setUserData] = useState();
  const [caseId, setCaseId] = useState();
  const [instruction, setInstruction] = useState();
  const [isLoading, setIsLoading] = useState();
  const [isTyping, setIsTyping] = useState();
  const [waitTime, setWaitTime] = useState(0);

  const getChatModel = (data) => [
    {
      role: 'system',
      content: chatModel({
        caseNumber: data._id,
        firstName: data.firstName,
        debtorCompanyName: data.debtorCompanyName,
        creditorCompanyName: data.creditorCompanyName,
        lastName: data.lastName,
        invoiceNumber: data.invoiceNumber,
        invoiceDate: data.invoiceDate,
        amount: data.amount,
        currency: data.currency,
        repayment: data.maxRepayment,
        email: data.email,
        paymentLink: `${process.env.REACT_APP_PAY_SERVER}/bank/info/${linkId}`,
      }),
    },
  ];

  const scrollIntoView = () => {
    // Get the div element
    const divElement = document.getElementById('chatBodyContent');
    // Scroll to the bottom of the div
    divElement.scrollTop = divElement.scrollHeight + 100;
  };

  useEffect(() => {
    setIsLoading(true);
    // get the user and case data
    Api.getLink(linkId).then((res) => {
      if (res.data.body.length === 0) {
        navigate('/');
      }
      setCaseId(res.data.body[0].case);
      Api.getCase(res.data.body[0].case).then((resp) => {
        setUserData(resp.data.body[0]);
      });
    });
    setTimeout(() => {
      scrollIntoView();
    }, 250);
  }, []);

  useEffect(() => {
    if (typeof userData !== 'undefined') {
      // check to see if the chat was already started
      Api.getChatBy({ case: caseId }).then((response) => {
        if (response.data.body !== null && response.data.body.messages !== '') {
          setMessageStack(response.data.body.messages);
          setIsLoading(false);
        } else {
          const instr = getChatModel(userData);
          setInstruction(instr);
          // init conversation
          Api.postAiMessage({ messages: getChatModel(userData), caseId: userData._id }).then((res) => {
            setMessageStack([{
              ...res.data,
              timestamp: moment().format('MMMM Do YYYY, h:mm:ss a'),
              displayName: process.env.REACT_APP_AGENT_NAME,
            }]);
            setIsLoading(false);
          });
        }
      });
    }
  }, [userData]);

  useEffect(() => {
    if (typeof message !== 'undefined') {
      setIsLoading(true);
      setMessageStack([...messageStack, {
        role: 'user',
        content: message,
        timestamp: moment().format('MMMM Do YYYY, h:mm:ss a'),
        displayName: `${userData?.firstName} ${userData?.lastName}`,
      }]);
    }
  }, [message]);

  useEffect(() => {
    if (messageStack.length > 0 && messageStack[messageStack.length - 1].role === 'user') {
      Api.postAiMessage({
        messages: instruction ? [...instruction, ...messageStack] : messageStack,
        caseId: userData._id,
      }).then((res) => {
        const stack = setTimeout(() => {
          setIsLoading(false);
          setMessageStack([...messageStack, { ...res.data, timestamp: moment().format('MMMM Do YYYY, h:mm:ss a'), displayName: process.env.REACT_APP_AGENT_NAME }]);
          clearTimeout(stack);
        }, (parseInt(res.data.content.length / process.env.REACT_APP_CHARS_PER_SECOND, 19) * 1000));
        const scroll = setTimeout(() => {
          scrollIntoView();
          clearTimeout(scroll);
        }, (parseInt(res.data.content.length / process.env.REACT_APP_CHARS_PER_SECOND, 19) * 1000) + 250);
      });
    }
  }, [messageStack]);

  useEffect(() => {
    setTimeout(() => {
      scrollIntoView();
    }, 250);
  }, [isLoading]);

  return (

    <div className="assistantWrapper">
      <div className="main main-app">

        <div id="chat-panel" className="chat-panel msg-show">
          <div>
            <div className="chat-body-header">
              <div className="chat-item">
                <div className="avatar online"><img src="../assets/img/img1.jpg" alt="" /></div>
                <div className="chat-item-body">
                  <h6 className="mb-1">{process.env.REACT_APP_AGENT_NAME}</h6>
                  <span>Active now</span>
                </div>
              </div>
            </div>
            <div id="chatBodyContent" className="chat-body-content ps" ref={chatbox}>
              {messageStack ? messageStack.map((item) => {
                if (item.role === 'assistant') {
                  return (
                    <MessageLeft
                      message={item.content}
                      timestamp={item.timestamp}
                      photoURL={item.photo}
                      displayName={item.displayName}
                      avatarDisp={item.avatarDisp}
                    />
                  );
                }
                if (item.role === 'user') {
                  return (
                    <MessageRight
                      message={item.content}
                      timestamp={item.timestamp}
                      photoURL={item.photo}
                      displayName={item.displayName}
                      avatarDisp={item.avatarDisp}
                    />
                  );
                }
                return true;
              }) : ''}
              { isTyping && (
              <div className="typing">
                {process.env.REACT_APP_AGENT_NAME}
                {' '}
                is typing...
              </div>
              )}

              <div id="msgEmpty" className="msg-item reverse d-none">
                <div className="msg-body">
                  <div className="row gx-3 row-cols-auto">
                    <div className="col">
                      <div className="msg-bubble">
                        {' '}
                        <span />
                      </div>
                    </div>
                    <div className="col">
                      <nav className="nav nav-icon">
                        <a href="/" className="nav-link"><i className="ri-reply-line" /></a>
                        <a href="/" className="nav-link"><i className="ri-more-fill" /></a>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>

              <div className="ps__rail-x"><div className="ps__thumb-x" tabIndex="0" /></div>
              <div className="ps__rail-y"><div className="ps__thumb-y" tabIndex="0" /></div>
            </div>
            {isLoading && (
            <div className="chat-bubble">
              <div className="typing">
                <div className="dot" />
                <div className="dot" />
                <div className="dot" />
              </div>
            </div>
            )}
            <div className="chat-body-footer">
              <div className="msg-box">
                <TextInput setChatEntry={setMessage} disabled={isLoading || userData?.deal} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalAssistant;
